import directus from "./lib-directus";

export async function getOrCreateAdhocSchedule(patient, scheduledDate) {
    // Find existing adhoc schedule for this episode
    const existingSchedule = await directus.items("patient_schedules").readByQuery({
        filter: {
            patient_id: patient.id,
            episode_id: patient.current_episode_id,
            type: "adhoc"
        },
        limit: 1
    });

    if (existingSchedule.data.length > 0) {
        const schedule = existingSchedule.data[0];
        const startDate = new Date(schedule.calculated_start_date);
        const endDate = new Date(schedule.calculated_end_date);
        const taskDate = new Date(scheduledDate);

        // Update date range if needed
        if (taskDate < startDate || taskDate > endDate) {
            const updatedSchedule = await directus.items("patient_schedules").updateOne(schedule.id, {
                calculated_start_date: taskDate < startDate ? scheduledDate : schedule.calculated_start_date,
                calculated_end_date: taskDate > endDate ? scheduledDate : schedule.calculated_end_date,
                client_id: patient.client_id
            });
            return updatedSchedule.id;
        }
        return schedule.id;
    }

    // Create new adhoc schedule
    const newSchedule = await directus.items("patient_schedules").createOne({
        patient_id: patient.id,
        episode_id: patient.current_episode_id,
        client_id: patient.client_id,
        type: "adhoc",
        status: "active",
        calculated_start_date: scheduledDate,
        calculated_end_date: scheduledDate
    });

    return newSchedule.id;
} 