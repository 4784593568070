import ApplicationState from "applicationstate";
import { html, LitElement } from "lit";
import { logout } from "shared/lib/lib-session";
import "shared/components/app-notifications-list";
import "shared/components/app-unread-sms-list";
import "shared/components/app-reviews-list";
import { WidgetClock } from "shared/components/widgets/widget-clock";
import { getCurrentClient } from "shared/lib/lib-user";

export default class AppHeader extends LitElement {
    createRenderRoot() {
        return this;
    }

    static get properties() {
        return {
            _notifications_count: { type: Number },
            _reviews_count: { type: Number },
            _reviews_analytics: { type: Object },
            _app_name: { type: String },
            _user: { type: Object },
            _title: { type: String },
            _user_menu_open: { type: Boolean },
            _notifications_menu_open: { type: Boolean },
            unread_sms_count: { type: Number },
            currentPath: { type: String },
            _current_client_id: { type: Object },
        };
    }

    constructor() {
        super();
        this._current_client_id = getCurrentClient();
        this._notifications_count = 0;
        this._reviews_count = 0;
        this._reviews_analytics = null;
        this.unread_sms_count = 0;
        this.unread_sms = [];
        this._app_name = ApplicationState.get('app.name');
        this._user = ApplicationState.get("app.user");
        this._title = null;
        this._user_menu_open = false;
        this._notifications_menu_open = false;
        this.handleSMSUpdated = this.handleSMSUpdated.bind(this);
        this.handleNoticationsUpdated = this.handleNoticationsUpdated.bind(this);
    }

    get unread_sms_count() {
        return this._unread_sms_count;
    }

    set unread_sms_count(value) {
        this._unread_sms_count = value;
        this.requestUpdate();
    }

    updated(changedProperties) {
        if (changedProperties.has('_notifications_count') || changedProperties.has('unread_sms_count')) {
            this.requestUpdate();
        }
    }

    firstUpdated() {
        this.notifications_menu_element = this.querySelector("#header-notifications-menu");
        this.notifications_menu_icon_element = this.querySelector("#header-notifications-menu-icon");
        this.user_menu_element = this.querySelector("#header-user-menu");
        this.user_menu_icon_element = this.querySelector("#header-user-menu-icon");
        this.unread_sms_element = this.querySelector("#header-unread-sms-menu");
        this.reviews_menu_element = this.querySelector("#header-reviews-menu");
        this.reviews_menu_icon_element = this.querySelector("#header-reviews-menu-icon");
    }

    connectedCallback() {
        super.connectedCallback();
        document.body.addEventListener("click", this.handleBodyClick.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.body.removeEventListener("click", this.handleBodyClick.bind(this));
    }

    render() {
        return html`
            <style>
                 .scene-title {
                    height: 14px;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #201b3a;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 0;
                    padding: 0;
                }
                .header-filled-icon {
                    color: var(--t-color-primary);
                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    margin: 0 1vw 0 1vw;
                }
                .header-menu {
                    display: none;
                    opacity: 0;
                    min-width: 120px;
                    transition: opacity 0.5s ease-in-out;
                    position: absolute;
                    right: 19px;
                    border: 1px solid rgba(0, 0, 0, 0.175);
                    border-radius: 0.375rem;
                    box-shadow: 0px 2px 10px rgba(0, 39, 77, 0.08);
                    background: var(--bs-white);
                    z-index: 999;
                }
                .header-menu li {
                    padding: 12px 15px;
                    display: flex;
                    /*align-items: center;*/
                    justify-content: center;
                    cursor: pointer;
                }
                .header-menu li:hover {
                    background-color: var(--bs-light);
                }
                #main-header a {
                    text-decoration: none;
                }
                #header-user-menu, #header-support-menu {
                    padding: 15px 0;
                }
                header#main-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                #main-menu-button {
                    text-decoration: none;
                    color: black;
                    font-size: 24px;
                    line-height: 24px;
                    margin-right: 20px;
                }
                #notifications-badge {
                    margin-left: -13px;
                }
                @media (min-width: 992px) {
                    header#main-header {
                        margin: 0 32px 0 30px;
                        height: 50px;
                    }
                    #main-menu-button {
                        display: none;
                    }
                    #notifications-badge {
                        margin-left: -20px;
                    }
                    .header-menu {
                        right: 33px;
                    }
                    .header-menu#header-support-menu {
                        right: 158px;
                    }
                }
            </style>
            <header id="main-header">
                <div style="display: flex; align-items: center;">
                    <a href="javascript:void(0);" id="main-menu-button"
                        class="material-symbols-outlined"
                        @click=${(e) => this.toggleSidebar()}>menu</a>
                 <h1 class="scene-title">${this._current_client_id.name || ``}<widget-clock></widget-clock></h1>
                    
                </div>
                <div id="header-charms">
                    <span class="header-charm">
                        <a
                            href="javascript:void(0)"
                            id="header-reviews-menu-icon"
                            style="text-decoration: none;"
                            @click=${(e) => this.handleReviewsClick(e)}
                        >
                            <span class="material-symbols-outlined header-filled-icon">verified</span>
                            ${this._reviews_count > 0 ? html`
                            <span
                                class="position-absolute translate-middle badge rounded-pill vb-warning"
                                id="reviews-badge">
                                ${this._reviews_count}
                            </span>
                            ` : ''}
                        </a>
                    </span>
                    <span class="header-charm">
                        <a
                            href="javascript:void(0)"
                            id="header-notifications-menu-icon"
                            style="text-decoration: none;"
                            @click=${(e) => this.handleUnreadSMSClick(e)}
                        >
                            <span class="material-symbols-outlined header-filled-icon">sms</span>
                            ${this.unread_sms_count > 0 ? html`
                            <span
                                class="position-absolute translate-middle badge rounded-pill vb-danger"
                                id="sms-badge">
                                ${this.unread_sms_count}
                            </span>
                            ` : ''}
                        </a>
                    </span>
                    <span class="header-charm">
    <a
        href="javascript:void(0)"
        id="header-notifications-menu-icon"
        style="text-decoration: none;"
        @click=${(e) => this.handleNotificationsClick(e)}
    >
        <span class="material-symbols-outlined header-filled-icon">notifications</span>
        ${this._notifications_count > 0 ? html`
            <span
                class="position-absolute translate-middle badge rounded-pill vb-danger"
                id="notifications-badge">
                ${this._notifications_count}
            </span>
        ` : ''}
    </a>
</span>
            <span class="header-charm">
                <a
                    href="javascript:void(0)"
                    id="header-user-menu-icon"
                            @click=${(e) => this.handleUserMenuClick(e)}
                            ><span class="material-symbols-outlined header-filled-icon">account_circle</span></a
            >
                    </span >
            <div class="header-menu" id="header-user-menu">
                <ul style="list-style: none outside; margin: 0; padding: 0;">
                    <!--<li><a href="javascript:void(0)">Account (coming soon)</a></li>-->
                    <li class="user-info">${this._user.first_name} ${this._user.last_name}</li>
                    <li class="user-info">${this._user.role?.name}</li>
                    <li><a href="javascript:void(0)" @click=${(e) => this.handleLogout(e)}>Logout</a></li>
            </ul>
                    </div >
            <div class="header-menu" id="header-notifications-menu">
                <app-notifications-list @notificationsupdated=${(_e) => this.handleNoticationsUpdated()}>
            </app-notifications-list>
                    </div >
            <div class="header-menu" id="header-unread-sms-menu">
                <app-unread-sms-list @smsupdated=${(_e) => this.handleSMSUpdated()}>
                </app-unread-sms-list>
            </div>
            <div class="header-menu" id="header-reviews-menu">
                <div class="p-2 border-bottom d-flex justify-content-between align-items-center">
                    <div>
                        ${this._reviews_analytics ? html`
                            <div class="d-flex align-items-center">
                                <span class="material-symbols-outlined me-1" style="color: #ffd700; font-size: 16px;">star</span>
                                <span class="fw-bold">${this._reviews_analytics.average_rating?.toFixed(1) || '0.0'}</span>
                            </div>
                        ` : ''}
                    </div>
                 <!--   <a href="/client/reviews" class="btn btn-sm btn-outline-primary">
                        View Analytics
                    </a> -->
                </div>
                <app-reviews-list @reviewsupdated=${(e) => this.handleReviewsUpdated(e)}>
                </app-reviews-list>
            </div>
        </div>
    </header>`;
    }

    handleBodyClick(e) {
        if (
            this.menu_icon_elements.includes(e.target) ||
            this.menu_icon_elements.includes(e.target.parentNode)
        ) {
            return;
        }

        for (let menu_element of this.menu_elements) {
            this.hideMenu(menu_element);
        }
    }

    async handleLogout(e) {
        await logout(`/${this._app_name}/`);
    }

    handleNotificationsClick(e) {
        e.stopPropagation();
        this.hideUserMenu();
        this.hideSMSMenu();
        if (this.notifications_menu_element.style.display === "block") {
            this.hideNotificationsMenu();
        } else {
            Object.assign(this.notifications_menu_element.style, {
                display: "block",
                opacity: 1,
            });
        }
    }

    handleUnreadSMSClick(e) {
        e.stopPropagation();
        this.hideNotificationsMenu();
        this.hideUserMenu();
        if (this.unread_sms_element.style.display === "block") {
            this.hideSMSMenu();
        } else {
            Object.assign(this.unread_sms_element.style, {
                display: "block",
                opacity: 1,
            });
        }
    }

    handleUserMenuClick(e) {
        e.stopPropagation();
        this.hideNotificationsMenu();
        this.hideSMSMenu();
        if (this.user_menu_element.style.display === "block") {
            this.hideUserMenu();
        } else {
            Object.assign(this.user_menu_element.style, {
                display: "block",
                opacity: 1,
            });
        }
    }

    handleNoticationsUpdated() {
        const notificationsList = this.querySelector("app-notifications-list");
        if (notificationsList) {
            this._notifications_count = notificationsList.notifications.length;
            this.requestUpdate();
        }
    }

    handleSMSUpdated() {
        const unreadSMSList = this.querySelector("app-unread-sms-list");
        if (unreadSMSList) {
            this.unread_sms_count = unreadSMSList.unread_sms.filter(sms => sms.status_reason === null).length;
            this.requestUpdate();
        }
    }

    handleBodyClick(event) {
        if (!this.notifications_menu_element.contains(event.target) &&
            !this.notifications_menu_icon_element.contains(event.target) &&
            !this.user_menu_element.contains(event.target) &&
            !this.user_menu_icon_element.contains(event.target) &&
            !this.unread_sms_element.contains(event.target) &&
            !this.reviews_menu_element.contains(event.target) &&
            !this.reviews_menu_icon_element.contains(event.target)) {
            this.hideNotificationsMenu();
            this.hideUserMenu();
            this.hideSMSMenu();
            this.hideReviewsMenu();
        }
    }

    hideNotificationsMenu() {
        this.notifications_menu_element.style.opacity = "0";
        setTimeout(() => {
            this.notifications_menu_element.style.display = "none";
        }, 500);
    }

    hideUserMenu() {
        this.user_menu_element.style.opacity = "0";
        setTimeout(() => {
            this.user_menu_element.style.display = "none";
        }, 500);
    }

    hideSMSMenu() {
        this.unread_sms_element.style.opacity = "0";
        setTimeout(() => {
            this.unread_sms_element.style.display = "none";
        }, 500);
    }

    handleReviewsClick(e) {
        // If holding Cmd (Mac) or Ctrl (Windows) key, go directly to reviews dashboard
        if (e.metaKey || e.ctrlKey) {
            window.location.href = '/client/reviews';
            return;
        }

        e.stopPropagation();
        this.hideNotificationsMenu();
        this.hideUserMenu();
        this.hideSMSMenu();
        if (this.reviews_menu_element.style.display === "block") {
            this.hideReviewsMenu();
        } else {
            Object.assign(this.reviews_menu_element.style, {
                display: "block",
                opacity: 1,
            });
        }
    }

    handleReviewsUpdated(e) {
        this._reviews_count = e.detail.reviews.length;
        if (e.detail.analytics) {
            this._reviews_analytics = e.detail.analytics;
        }
        this.requestUpdate();
    }

    hideReviewsMenu() {
        this.reviews_menu_element.style.opacity = "0";
        setTimeout(() => {
            this.reviews_menu_element.style.display = "none";
        }, 500);
    }

    toggleSidebar() {
        document.dispatchEvent(new CustomEvent("sidebar"));
    }
}

customElements.define("app-header", AppHeader);
