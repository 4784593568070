import { html, render } from "lit";
import directus from "../../lib/lib-directus";
import ModalBase from "../util/modal-base";
import { getCurrentClientId } from "../../lib/lib-user";

export default class AppSettingsMessageModal extends ModalBase {
    constructor() {
        super();
        this.message = {};
        this.isEdit = false;
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <style>
                .modal-cancel-btn {
                    background-color: var(--t-color-grey);
                    border-color: var(--t-color-grey);
                }
                .modal-cancel-btn:hover {
                    background-color: var(--t-color-grey-darkened);
                    border-color: var(--t-color-grey-darkened);
                }
                .modal-confirm-btn {
                    background-color: var(--t-color-danger);
                    border-color: var(--t-color-danger);
                }
                .modal-confirm-btn:hover {
                    background-color: var(--t-color-danger-darkened);
                    border-color: var(--t-color-danger-darkened);
                }
            </style>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${this.isEdit ? "Update" : "New"} SMS Template</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mb-3"> 
                                <label for="template_name" class="form-label">Name</label>
                                <input value=${this.message?.template_name} id="template_name" class="form-control" aria-label="SMS Name"/>
                            </div>
                            <div class="mb-3">
                                <label for="template_content" class="form-label">Content</label>
                                <textarea id="template_content" class="form-control" aria-label="Content">${this.message?.template_content}</textarea>
                            </div>
                            <div class="mb-3">
                                <label for="template_status" class="form-label">Status</label>
                                <select id="template_status" class="form-select" aria-label="Status">
                                    <option value="draft" ?selected=${this.message?.status == "draft"}>Draft</option>
                                    <option value="published" ?selected=${this.message?.status == "published"}>Published</option>
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="template_type" class="form-label">Type</label>
                                <select id="template_type" class="form-select" aria-label="Type">
                                    <option value="checkin" ?selected=${this.message?.template_type == "self"}>Check-In</option>
                                    <option value="education" ?selected=${this.message?.template_type == "education"}>Education</option>
                                    <option value="general" ?selected=${this.message?.template_type == "general"}>General</option>
                                    <option value="clinician" ?selected=${this.message?.template_type == "clinician"}>Clinician</option>
                                    <option value="other" ?selected=${this.message?.template_type == "other"}>Other</option>
                                </select>
                                </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button class="btn btn-primary btn-danger modal-confirm-btn" @click=${(e) => this.handleConfirmClick()}>
                            ${this.isEdit ? "Update" : "Create"}
                        </button>
                    </div>
                </div>
            </div>
            `;
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async handleConfirmClick() {
        const template_name = this.querySelector('#template_name').value;
        const template_content = this.querySelector('#template_content').value;
        const template_type = this.querySelector('#template_type').value;
        const status = this.querySelector('#template_status').value;
        const client_id = getCurrentClientId();
        const message = { template_name, template_content, template_type, status, client_id };

        if (this.isEdit) {
            await directus.items('sms_templates').updateOne(this.message.id, message);
        } else {
            await directus.items("sms_templates").createOne(message);
        }
        this.dismiss({ confirmed: true });

    }
}

customElements.define("app-settings-message-modal", AppSettingsMessageModal);
