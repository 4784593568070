import { html, LitElement } from "lit";
import { getCurrentClientId } from "../lib/lib-user";
import ClientSettings from "../lib/lib-client-settings";

export default class AppReviewsList extends LitElement {
    static get properties() {
        return {
            reviews: { type: Array },
            loading: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.reviews = [];
        this.loading = true;
        this.refreshInterval = null;
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadReviews();
        // Refresh reviews every 5 minutes
        this.refreshInterval = setInterval(() => {
            this.loadReviews();
        }, 5 * 60 * 1000);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    async loadReviews() {
        try {
            this.loading = true;
            const clientId = getCurrentClientId();
            const settings = await ClientSettings.getSettings(clientId);
            const placeId = settings?.ALL?.google_place_id?.value;

            if (!placeId) {
                this.reviews = [];
                return;
            }

            console.log('Fetching reviews for place ID:', placeId);
            const response = await fetch(`/vbh/google/places/${placeId}/reviews?client_id=${clientId}`);
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Failed to fetch reviews:', response.status, errorText);
                throw new Error(`Failed to fetch reviews: ${response.status} ${errorText}`);
            }

            const data = await response.json();
            console.log('Reviews response:', data);
            this.reviews = data.reviews || data; // Handle both new and old response format
            this.dispatchEvent(new CustomEvent('reviewsupdated', {
                detail: {
                    reviews: this.reviews,
                    analytics: data.analytics
                }
            }));
        } catch (error) {
            console.error('Error loading reviews:', error);
            this.reviews = [];
        } finally {
            this.loading = false;
            this.requestUpdate();
        }
    }

    formatTime(time) {
        return new Date(time).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }

    renderStars(rating) {
        return html`
            <div class="stars">
                ${Array(5).fill(0).map((_, i) => html`
                    <span class="material-symbols-outlined" style="color: ${i < rating ? '#ffd700' : '#ccc'}; font-size: 14px;">
                        star
                    </span>
                `)}
            </div>
        `;
    }

    render() {
        if (this.loading) {
            return html`
                <div class="text-center p-3">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            `;
        }

        if (!this.reviews.length) {
            return html`
                <div class="text-center p-3">
                    <p class="text-muted mb-0">No reviews yet</p>
                </div>
            `;
        }

        return html`
            <style>
                .review-item {
                    padding: 12px 15px;
                    border-bottom: 1px solid #eee;
                }
                .review-item:last-child {
                    border-bottom: none;
                }
                .review-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 4px;
                }
                .review-author {
                    font-weight: 600;
                    font-size: 14px;
                }
                .review-time {
                    color: #666;
                    font-size: 12px;
                }
                .review-text {
                    font-size: 13px;
                    color: #333;
                    margin: 4px 0;
                    max-height: 100px;
                    overflow-y: auto;
                    white-space: pre-wrap;
                }
                .stars {
                    display: flex;
                    gap: 2px;
                }
            </style>
            <div style="max-height: 400px; overflow-y: auto; min-width: 300px;">
                ${this.reviews.map(review => html`
                    <div class="review-item">
                        <div class="review-header">
                            <span class="review-author">${review.author_name}</span>
                            <span class="review-time">${this.formatTime(review.review_date || review.time)}</span>
                        </div>
                        ${this.renderStars(review.rating)}
                        <div class="review-text">${review.text}</div>
                    </div>
                `)}
            </div>
        `;
    }
}

customElements.define('app-reviews-list', AppReviewsList);
