import { html, render, LitElement } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import ApplicationState from "applicationstate";
import "shared/components/form/form-input";
import ClientSettings from "shared/lib/lib-client-settings";
import { easepick, TimePlugin } from '@easepick/bundle';
import GooglePlaces from 'shared/lib/lib-google-places';

export default class SceneSettingsCompany extends LitElement {
    static get properties() {
        return {
            settings: { type: Object },
            isAdmin: { type: Boolean },
            selectedPlace: { type: Object },
            businessInfo: { type: Object }
        };
    }
    createRenderRoot() {
        return this;
    }
    constructor() {
        super();
        const user = ApplicationState.get('app.user');
        this.isAdmin = user?.role?.name === 'Administrator';
        this.settings = {};
        this.selectedPlace = null;
        this.businessInfo = null;
    }

    async firstUpdated() {
        const client_id = getCurrentClientId();
        if (!client_id) {
            throw new Error('Invalid client id');
        }
        await this.loadSettings();

        // Initialize Google Places Autocomplete
        const scriptLoaded = await GooglePlaces.loadGooglePlacesScript();
        if (!scriptLoaded) {
            // Show warning to user
            const searchDiv = this.querySelector('#place-search').parentElement;
            const warning = document.createElement('div');
            warning.className = 'alert alert-warning mt-2';
            warning.innerHTML = `
                <i class="bi bi-exclamation-triangle"></i>
                It looks like an ad blocker might be preventing the business search from working. 
                Please disable your ad blocker for this site and refresh the page.
            `;
            searchDiv.appendChild(warning);
            return;
        }

        const searchInput = this.querySelector('#place-search');
        GooglePlaces.initAutocomplete(searchInput, async (place) => {
            this.selectedPlace = place;
            const client_id = getCurrentClientId();
            const reviewLink = GooglePlaces.getWriteReviewUrl(place.place_id);

            try {
                // Save place ID
                if (!this.settings?.ALL?.google_place_id) {
                    await ClientSettings.addSetting({
                        client_id: client_id,
                        key: 'google_place_id',
                        value: place.place_id,
                        group: null
                    });
                } else {
                    await ClientSettings.setValue(client_id, 'google_place_id', place.place_id);
                }

                // Save business name
                if (!this.settings?.ALL?.business_name) {
                    await ClientSettings.addSetting({
                        client_id: client_id,
                        key: 'business_name',
                        value: place.name,
                        group: null
                    });
                } else {
                    await ClientSettings.setValue(client_id, 'business_name', place.name);
                }

                // Save business address
                if (!this.settings?.ALL?.business_address) {
                    await ClientSettings.addSetting({
                        client_id: client_id,
                        key: 'business_address',
                        value: place.formatted_address,
                        group: null
                    });
                } else {
                    await ClientSettings.setValue(client_id, 'business_address', place.formatted_address);
                }

                // Save review link
                if (!this.settings?.ALL?.google_review_link) {
                    await ClientSettings.addSetting({
                        client_id: client_id,
                        key: 'google_review_link',
                        value: reviewLink,
                        group: null
                    });
                } else {
                    await ClientSettings.setValue(client_id, 'google_review_link', reviewLink);
                }

                // Update local settings
                this.settings = await ClientSettings.getSettings(client_id);
                this.requestUpdate();
            } catch (error) {
                console.error('Failed to save place ID:', error);
            }
        });
    }

    async loadBusinessInfo() {
        const placeId = this.settings?.ALL?.google_place_id?.value;
        if (placeId) {
            this.businessInfo = await GooglePlaces.getBusinessInfo(placeId);
            this.requestUpdate();
        }
    }

    render() {
        return html`
          <!--  <h5>Company Settings</h5> -->
            
            <!-- Google Business Profile Section -->
            ${this.renderGoogleSection()}

            <div class="row">
                <div class="col-4 mb-3">
                    <label for="default_timezone" class="settings-label" style="margin-bottom: 5px;">Default timezone</label>
                    <select class="form-select setting" id="default_timezone" @change=${this.markDirty}>
                        ${['America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles', 'America/Anchorage', 'Pacific/Honolulu'].map(timezone => html`
                            <option ?selected=${this.settings?.ALL?.default_timezone?.value == timezone} value=${timezone}>${timezone}</option>
                        `)}
                    </select>
                    <small>Timezone used for scheduling</small>
                </div>
            </div>
            
            ${this.isAdmin ? this.renderAdminSettings() : ''}
            
            <div class="row">
                <div class="mb-3">
                    <button @click=${this.save} class="settings-save btn btn-primary">Save</button>
                </div>
            </div>
        `;
    }

    renderGoogleSection() {
        const placeId = this.selectedPlace?.place_id || this.settings?.ALL?.google_place_id?.value;
        const reviewLink = this.settings?.ALL?.google_review_link?.value;
        const businessName = this.selectedPlace?.name || this.settings?.ALL?.business_name?.value;
        const businessAddress = this.selectedPlace?.formatted_address || this.settings?.ALL?.business_address?.value;

        return html`
            <div class="row mb-4 vb-client-settings">
                <div class="col-12">
                    <h6>Google Business Profile</h6>
                    <div class="col-8 mb-3">
                        <input 
                            type="text" 
                            class="form-control" 
                            id="place-search" 
                            placeholder="Start typing your business name..."
                        >
                        <small class="form-text text-muted">
                            *Search for your business name and select it from the dropdown
                        </small>
                    </div>
                    ${placeId ? html`
                        <div class="col-8">
                            <div class="card g-card">
                                <div class="card-body g-card-body">
                                    <h5 class="card-title">${businessName}</h5>
                                    <p class="card-text">
                                        <i class="bi bi-geo-alt"></i> ${businessAddress}
                                    </p>
                                    <div class="input-group">
                                        <input type="text" 
                                            class="form-control" 
                                            readonly 
                                            value=${reviewLink || GooglePlaces.getWriteReviewUrl(placeId)}
                                        >
                                        <button class="btn btn-outline-secondary" type="button" 
                                            @click=${() => GooglePlaces.copyReviewLinkToClipboard(placeId)}>
                                            <i class="bi bi-clipboard"></i> Copy Review Link
                                        </button>
                                    </div>
                                    <small class="text-muted mt-2 d-block">
                                        Place ID: ${placeId}
                                    </small>
                                </div>
                            </div>
                        </div>
                    ` : ''}
                </div>
            </div>
        `;
    }

    renderAdminSettings() {
        return html`
            <div class="row">

            <!--    <div class="col-4 mb-3">
                    <label for="telynx_profile_id" class="form-label settings-label">Telynx Profile ID</label>
                    <input type="text" class="form-control setting" id="telynx_profile_id" .value=${this.settings?.ALL?.telynx_profile_id?.value || ''} @change=${this.markDirty} placeholder="Enter Telynx Profile ID">
                </div>
            -->
                <div class="col-4 mb-3">
                    <label for="ehr_username" class="form-label settings-label">EHR Username</label>
                    <input type="text" class="form-control setting" id="ehr_username" .value=${this.settings?.ALL?.ehr_username?.value || ''} @change=${this.markDirty} placeholder="The username for the EHR">
                </div>
                <div class="col-4 mb-3">
                    <label for="ehr_password" class="form-label settings-label">EHR Password</label>
                    <input type="password" class="form-control setting" id="ehr_password" .value=${this.settings?.ALL?.ehr_password?.value || ''} @change=${this.markDirty} placeholder="The password for the EHR">
                </div>
            </div>
                <div class="mb-3">
                    <div class="form-check form-switch settings-switch">
                        <input class="form-check-input setting" type="checkbox" role="switch" id="selfmanaged" ?checked=${this.settings?.ALL?.selfmanaged?.value} @change=${this.markDirty}>
                        <label for="selfmanaged" class="form-check-label">Self Managed</label>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="form-check form-switch settings-switch">
                        <input class="form-check-input setting" type="checkbox" role="switch" id="is_hospice" ?checked=${this.settings?.ALL?.is_hospice?.value} @change=${this.markDirty}>
                        <label for="is_hospice" class="form-check-label">Hospice Agency</label>
                    </div>
                </div>
        `;
    }

    markDirty(e) {
        e.target.dataset.dirty = true;
    }

    async save() {
        const client_id = getCurrentClientId();
        const settingElements = this.querySelectorAll('.setting');
        const saveButton = this.querySelector('button');

        try {
            // Disable button and show saving state
            saveButton.disabled = true;
            saveButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...';

            // Save all dirty settings
            for (let settingElement of settingElements) {
                if (settingElement.dataset.dirty) {
                    let settingValue;
                    if (settingElement.type === 'checkbox') {
                        // Convert boolean to string for consistent storage
                        settingValue = settingElement.checked.toString();
                    } else {
                        settingValue = settingElement.value?.toString() || '';
                    }

                    console.log(`Saving setting ${settingElement.id} with value:`, settingValue, 'Type:', typeof settingValue);

                    // Check if setting exists before trying to save it
                    const settingKey = settingElement.id;

                    // Special handling for is_hospice to ensure it's in the company group
                    if (settingKey === 'is_hospice') {
                        if (this.settings?.company?.is_hospice) {
                            await ClientSettings.setValue(client_id, settingKey, settingValue);
                        } else {
                            // Create new setting in the company group
                            await ClientSettings.addSetting({
                                client_id: client_id,
                                key: settingKey,
                                value: settingValue,
                                group: 'company'
                            });
                        }
                    } else if (this.settings?.ALL?.[settingKey]) {
                        await ClientSettings.setValue(client_id, settingKey, settingValue);
                    } else {
                        // Create new setting if it doesn't exist
                        await ClientSettings.addSetting({
                            client_id: client_id,
                            key: settingKey,
                            value: settingValue,
                            group: null
                        });
                    }
                    settingElement.dataset.dirty = false;
                }
            }

            // Reload settings without page refresh
            await this.loadSettings();

            // Show success feedback
            saveButton.classList.remove('btn-primary');
            saveButton.classList.add('btn-success');
            saveButton.innerHTML = '<i class="bi bi-check"></i> Saved';

            // Reset button after 2 seconds
            setTimeout(() => {
                saveButton.disabled = false;
                saveButton.classList.remove('btn-success');
                saveButton.classList.add('btn-primary');
                saveButton.innerHTML = 'Save';
            }, 2000);

            // Request component update
            this.requestUpdate();

        } catch (error) {
            console.error('Failed to save settings:', error);

            // Show error feedback
            saveButton.classList.remove('btn-primary');
            saveButton.classList.add('btn-danger');
            saveButton.innerHTML = '<i class="bi bi-exclamation-triangle"></i> Error Saving';

            // Reset button after 3 seconds
            setTimeout(() => {
                saveButton.disabled = false;
                saveButton.classList.remove('btn-danger');
                saveButton.classList.add('btn-primary');
                saveButton.innerHTML = 'Save';
            }, 3000);
        }
    }

    async loadSettings() {
        const client_id = getCurrentClientId();
        this.settings = await ClientSettings.getSettings(client_id);

        // Ensure is_hospice setting exists in the company group
        if (!this.settings?.company?.is_hospice) {
            try {
                console.log('Initializing is_hospice setting in company group');
                await ClientSettings.addSetting({
                    client_id: client_id,
                    key: 'is_hospice',
                    value: 'false', // Use string value for consistency
                    group: 'company'
                });

                // Reload settings after adding
                this.settings = await ClientSettings.getSettings(client_id);
                console.log('Settings after initialization:', this.settings);
            } catch (error) {
                console.error('Failed to initialize is_hospice setting:', error);
            }
        } else {
            console.log('is_hospice setting already exists in company group:', this.settings.company.is_hospice);
        }
    }

}

customElements.define("scene-settings-company", SceneSettingsCompany);
