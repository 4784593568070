import { html, render } from "lit";
import directus from "shared/lib/lib-directus";
import { getCurrentClientId } from "shared/lib/lib-user";
import AppSettingsMessageModal from "shared/components/settings/app-settings-message-modal";
import "shared/components/filter/app-filter";
import "shared/components/app-report-filter";
import 'shared/components/filter/app-filter-data-view-server';

export default class SceneSettingsSMS extends HTMLElement {
    constructor() {
        super();

        // Define types
        const types = {
            "checkin": "Check-In",
            "education": "Education",
            "general": "General",
            "clinician": "Clinician",
        };

        this.type_options = Object.entries(types).map(([value, label]) => ({
            value,
            label
        }));

        this.status_options = [
            { value: "published", label: "Published" },
            { value: "draft", label: "Draft" }
        ];

        // Configure tabulator options
        this._options = {
            ajaxURL: "/items/sms_templates",
            ajaxParams: {},
            sortMode: "remote",
            filterMode: "remote",
            layout: "fitColumns",
            progressiveLoad: "scroll",
            selectable: 1,
            selectableRollingSelection: false,
            rowClick: function (e, row) {
                row.select();
            }
        };

        // Selected columns for data fetching
        this._selected_columns = [
            "status",
            "id",
            "client_id",
            "template_name",
            "template_content",
            "template_type"
        ];

        // Table column configuration
        this._table_columns = [
            {
                title: "",
                field: "status",
                width: 50,
                formatter: function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let status = rowData.status;
                    const span = document.createElement('span');
                    span.textContent = status === "published" ? "🟢" : "🔴";
                    return span;
                },
            },
            {
                title: "Name",
                field: "template_name",
                width: 250

            },
            {
                title: "Content",
                field: "template_content",
                formatter: "textarea"
            },
            {
                title: "Type",
                field: "template_type",
                width: 100
            }
        ];

        // Filter configuration
        this._filter_config = {
            collection: {
                name: "sms_templates",
                auto_configure: false,
                depth: 0
            },
            search_fields: [
                "template_name",
                "template_content"
            ],
            field_rules: {
                sms_templates: {
                    mode: "whitelist",
                    fields: [
                        "template_name",
                        "template_content",
                        "template_type",
                        "status"
                    ]
                }
            }
        };

        // Initialize filter state
        this._filter_state = {
            user_search: "",
            group: {
                type: "_and",
                filters: [],
                groups: []
            }
        };
    }

    get filter_state() {
        return this._filter_state;
    }

    set filter_state(value) {
        this._filter_state = value;
        this.render();
    }

    handleFilterChange(filter_state) {
        this.filter_state = filter_state;
        this.render();
    }

    handleTypeFilterChange(e) {
        const selectedTypes = e.detail;
        let newFilterState = { ...this.filter_state };

        // Remove any existing template_type filters
        newFilterState.group.filters = newFilterState.group.filters.filter(f => f.field !== "template_type");

        // Add new template_type filter if types are selected
        if (selectedTypes && selectedTypes.length > 0) {
            newFilterState.group.filters.push({
                field: "template_type",
                op: "_in",
                value: selectedTypes
            });
        }

        this.filter_state = newFilterState;
        this.render();
    }

    handleStatusFilterChange(e) {
        const selectedStatus = e.detail;
        let newFilterState = { ...this.filter_state };

        // Remove any existing status filters
        newFilterState.group.filters = newFilterState.group.filters.filter(f => f.field !== "status");

        // Add new status filter if status is selected
        if (selectedStatus && selectedStatus.length > 0) {
            newFilterState.group.filters.push({
                field: "status",
                op: "_in",
                value: selectedStatus
            });
        }

        this.filter_state = newFilterState;
        this.render();
    }




    handleSelect(message) {
        this.handleEdit(message);
    }

    clearTablePersistence() {
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('tabulator-sms_templates')) {
                localStorage.removeItem(key);
            }
        });
        window.location.reload();
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <style>
                .dot {
                    height: 10px;
                    width: 10px;
                    background-color: green;
                    border-radius: 50%;
                }
                .filters-container {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                }
            </style>
            <div class="container-fluid sms-settings-container" style="height: 100%; padding: 0;">

                

                        <div class="filters-container sms-settings-filters">
                            <app-filter
                                @filter_change=${e => this.handleFilterChange(e.detail)}
                                .filter_state=${this.filter_state}
                                .config=${this._filter_config}
                                .enable_aggregate=${false}
                                .disable_sort=${false}
                                .show_filters=${false}
                                .expanded=${false}
                            ></app-filter>
                            
                            <app-report-filter
                                .title=${"Filter by Type"}
                                .options=${this.type_options}
                                .multiselect=${true}
                                @optionclick=${e => this.handleTypeFilterChange(e)}
                            ></app-report-filter>

                            <app-report-filter
                                .title=${"Filter by Status"}
                                .options=${this.status_options}
                                .multiselect=${true}
                                @optionclick=${e => this.handleStatusFilterChange(e)}
                            ></app-report-filter>


                            <span
                            @click=${_e => this.handleAddClick()}
                            class="material-symbols-outlined"
                            style='
                                font-size: 31px;
                                cursor: pointer;
                                margin: 0 0 -4px 20px;
                                color: var(--t-color-primary);
                                font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                            '
                        >
                            add_circle
                        </span>
                            <!--<button 
                                class="btn light-purple-btn"
                                style="margin-left: auto; font-size: 10px; height: 30px !important;"
                                @click=${() => this.clearTablePersistence()}
                            >
                                RESET LAYOUT
                            </button> -->
                        </div>
        
                <div class="row" style="margin-top:10px;">
                    <div class="col">
                        <app-filter-data-view-server
                            .config=${this._filter_config}
                            .filter_state=${this.filter_state}
                            .options=${this._options}
                            .table_columns=${this._table_columns}
                            .selected_columns=${this._selected_columns}
                            .additional_filters=${{
                    type: "_and",
                    filters: [
                        {
                            field: "client_id",
                            op: "_eq",
                            value: getCurrentClientId()
                        }
                    ]
                }}
                            @row-click=${e => this.handleSelect(e.detail)}
                        ></app-filter-data-view-server>
                    </div>
                </div>
            </div>
            `;
        }

        Object.assign(this.style, {
            display: "block",
        })

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async handleEdit(message) {
        const modal = new AppSettingsMessageModal();
        modal.message = message;
        modal.isEdit = true;
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            const table = this.querySelector('app-filter-data-view-server');
            if (table) {
                table.refreshData();
            }
        }
    }

    async handleAddClick() {
        const modal = new AppSettingsMessageModal();
        await modal.showModal();
        const res = await modal.onDidDismiss();
        if (res?.confirmed) {
            const table = this.querySelector('app-filter-data-view-server');
            if (table) {
                table.refreshData();
            }
        }
    }
}

customElements.define("scene-settings-sms", SceneSettingsSMS);
