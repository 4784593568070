import { html, LitElement } from "lit";
import { getCurrentClientId } from "../lib/lib-user";
import ClientSettings from "../lib/lib-client-settings";
import ApexCharts from 'apexcharts';

export default class AppReviewsDashboard extends LitElement {
    static get properties() {
        return {
            analytics: { type: Object },
            loading: { type: Boolean },
            timeRange: { type: String }
        };
    }

    constructor() {
        super();
        this.analytics = null;
        this.loading = true;
        this.timeRange = '30d'; // Default to 30 days
        this.charts = {
            ratings: null,
            trend: null
        };
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        this.loadAnalytics();
    }

    async loadAnalytics() {
        try {
            this.loading = true;
            const clientId = getCurrentClientId();

            // Calculate date range
            const end_date = new Date().toISOString();
            const start_date = new Date();
            switch (this.timeRange) {
                case '7d':
                    start_date.setDate(start_date.getDate() - 7);
                    break;
                case '30d':
                    start_date.setDate(start_date.getDate() - 30);
                    break;
                case '90d':
                    start_date.setDate(start_date.getDate() - 90);
                    break;
                case '1y':
                    start_date.setFullYear(start_date.getFullYear() - 1);
                    break;
            }

            const response = await fetch(
                `/vbh/reviews/analytics/${clientId}?start_date=${start_date.toISOString()}&end_date=${end_date}`
            );

            if (!response.ok) {
                throw new Error('Failed to fetch analytics');
            }

            this.analytics = await response.json();
            this.requestUpdate();

            // Wait for the DOM to update
            await this.updateComplete;
            this.renderCharts();

        } catch (error) {
            console.error('Error loading analytics:', error);
            this.analytics = null;
        } finally {
            this.loading = false;
        }
    }

    renderCharts() {
        this.renderRatingDistribution();
        this.renderTrendChart();
    }

    renderRatingDistribution() {
        const element = this.querySelector('#rating-distribution-chart');
        if (!element) return;

        if (this.charts.ratings) {
            this.charts.ratings.destroy();
        }

        // Initialize with empty data if analytics is not available
        const data = this.analytics?.rating_distribution || Array(5).fill(0).map((_, i) => ({
            rating: i + 1,
            count: 0
        }));

        const options = {
            chart: {
                type: 'bar',
                height: 350,
                animations: {
                    enabled: true
                }
            },
            series: [{
                name: 'Number of Reviews',
                data: data.map(d => d.count)
            }],
            xaxis: {
                categories: data.map(d => `${d.rating} Star${d.rating === 1 ? '' : 's'}`),
                labels: {
                    style: {
                        colors: '#666'
                    }
                }
            },
            yaxis: {
                min: 0,
                tickAmount: 4,
                labels: {
                    style: {
                        colors: '#666'
                    }
                }
            },
            colors: ['#007bff'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    dataLabels: {
                        position: 'top'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#666']
                }
            },
            noData: {
                text: 'No reviews available',
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: '#666',
                    fontSize: '14px'
                }
            }
        };

        this.charts.ratings = new ApexCharts(element, options);
        this.charts.ratings.render();
    }

    renderTrendChart() {
        const element = this.querySelector('#review-trend-chart');
        if (!element) return;

        if (this.charts.trend) {
            this.charts.trend.destroy();
        }

        // Initialize with empty data if analytics is not available
        const dates = this.analytics?.reviews_by_date ? Object.keys(this.analytics.reviews_by_date).sort() : [];
        const counts = dates.length > 0 ? dates.map(date => this.analytics.reviews_by_date[date]) : [0];

        // If no dates, create a default range
        if (dates.length === 0) {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(lastWeek.getDate() - 7);
            dates.push(lastWeek.toISOString().split('T')[0], today.toISOString().split('T')[0]);
        }

        const options = {
            chart: {
                type: 'area',
                height: 350,
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: true
                }
            },
            series: [{
                name: 'Reviews',
                data: counts
            }],
            xaxis: {
                categories: dates.map(date => new Date(date).toLocaleDateString()),
                labels: {
                    style: {
                        colors: '#666'
                    }
                }
            },
            yaxis: {
                min: 0,
                tickAmount: 4,
                labels: {
                    style: {
                        colors: '#666'
                    }
                }
            },
            colors: ['#28a745'],
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.2,
                    stops: [0, 90, 100]
                }
            },
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            noData: {
                text: 'No reviews available',
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    color: '#666',
                    fontSize: '14px'
                }
            }
        };

        this.charts.trend = new ApexCharts(element, options);
        this.charts.trend.render();
    }

    handleTimeRangeChange(e) {
        this.timeRange = e.target.value;
        this.loadAnalytics();
    }

    render() {
        if (this.loading) {
            return html`
                <div class="text-center p-4">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            `;
        }

        const showEmptyState = !this.analytics || !this.analytics.rating_distribution;
        const total_reviews = showEmptyState ? 0 : this.analytics.total_reviews;
        const average_rating = showEmptyState ? 0 : this.analytics.average_rating;
        const week_over_week = showEmptyState ? null : this.analytics.week_over_week;

        return html`
            <style>
                .metrics-grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    gap: 1rem;
                    margin-bottom: 2rem;
                }
                .metric-card {
                    background: white;
                    border-radius: 8px;
                    padding: 1rem;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }
                .metric-value {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
                .metric-label {
                    font-size: 14px;
                    color: #666;
                }
                .chart-container {
                    background: white;
                    border-radius: 8px;
                    padding: 1rem;
                    margin-bottom: 1rem;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }
                .time-range-selector {
                    margin-bottom: 1rem;
                }
                .empty-state {
                    text-align: center;
                    padding: 2rem;
                    color: #666;
                }
            </style>

            <div class="time-range-selector">
                <select class="form-select" @change=${this.handleTimeRangeChange}>
                    <option value="7d" ?selected=${this.timeRange === '7d'}>Last 7 days</option>
                    <option value="30d" ?selected=${this.timeRange === '30d'}>Last 30 days</option>
                    <option value="90d" ?selected=${this.timeRange === '90d'}>Last 90 days</option>
                    <option value="1y" ?selected=${this.timeRange === '1y'}>Last year</option>
                </select>
            </div>

            <div class="metrics-grid">
                <div class="metric-card">
                    <div class="metric-value">${total_reviews}</div>
                    <div class="metric-label">Total Reviews</div>
                </div>
                <div class="metric-card">
                    <div class="metric-value">${average_rating.toFixed(1)}</div>
                    <div class="metric-label">Average Rating</div>
                </div>
                ${week_over_week ? html`
                    <div class="metric-card">
                        <div class="metric-value">
                            ${week_over_week.change_percentage > 0 ? '+' : ''}${week_over_week.change_percentage.toFixed(1)}%
                        </div>
                        <div class="metric-label">Week over Week</div>
                    </div>
                ` : ''}
            </div>

            ${showEmptyState ? html`
                <div class="empty-state">
                    <p>No review data available yet. Reviews will appear here once customers leave them on your Google Business Profile.</p>
                    <a href="/client/settings" class="btn btn-outline-primary mt-2">
                        <i class="bi bi-gear"></i> Configure Google Business Profile
                    </a>
                </div>
            ` : ''}

            <div class="chart-container">
                <h5>Rating Distribution</h5>
                <div id="rating-distribution-chart"></div>
            </div>

            <div class="chart-container">
                <h5>Review Trend</h5>
                <div id="review-trend-chart"></div>
            </div>
        `;
    }
}

customElements.define('app-reviews-dashboard', AppReviewsDashboard);
