import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import DataDefinition from "../../lib/lib-data-definition";
import NotificationRules from "../../lib/lib-notification-settings";
import { getCurrentClientId } from "../../lib/lib-user";

import "../form/form-select"
import directus from "../../lib/lib-directus";
//import { del } from "telnyx/lib/TelnyxMethod.basic";

export default class AppSettingsCareTeamNotifications extends ModalBase {
    get alert_rules() {
        return this._alert_rules;
    }

    set alert_rules(value) {
        this._alert_rules = value;
        this.render();
    }

    set caregiver(value) {
        this._caregiver = value;
        if (!value) return;
        const client_id = getCurrentClientId();
        this.client_access = value.client_access.find((r) => r.client_id === client_id);
    }

    get caregiver() {
        return this._caregiver;
    }

    set client_access(value) {
        this._client_access = value || {};
        this.render();
    }

    get client_access() {
        return this._client_access;
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = !!value;
        this.render();
    }

    get tab() {
        return this._tab;
    }

    set tab(value) {
        this._tab = value;
        this.render();
    }

    constructor() {
        super();

        this._caregiver = null;
        this.clinical_role_choices = [];
        this.qualification_level_conditions = [];
        this.rule_list = [];
        this._disabled = false;
        this._tab = "notifications";
    }

    connectedCallback() {
        this.template = () => {
            const { caregiver, client_access } = this;

            let qualification_level_choices = [];
            if (this.client_access.clinical_role) {
                let conditions = this.qualification_level_conditions.find((i) => i.name === this.client_access.clinical_role + "_conditions");
                qualification_level_choices = conditions?.options.choices || [];
            }

            return html`
            <style>
                .modal-cancel-btn {
                    background-color: var(--t-color-grey);
                    border-color: var(--t-color-grey);
                }
                .modal-cancel-btn:hover {
                    background-color: var(--t-color-grey-darkened);
                    border-color: var(--t-color-grey-darkened);
                }
                .modal-confirm-btn {
                    background-color: var(--t-color-danger);
                    border-color: var(--t-color-danger);
                }
                .modal-confirm-btn:hover {
                    background-color: var(--t-color-danger-darkened);
                    border-color: var(--t-color-danger-darkened);
                }
            </style>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" @click=${e => e.stopImmediatePropagation()}>
                    <div class="modal-header">
                        <h5 class="modal-title">${caregiver ? caregiver.first_name + " " + caregiver.last_name : ""}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <a href="javascript:void(0);" @click=${(e) => this.resetToDefault()}>Reset to default</a>
                            </div>
                            <div class="col text-end" style="font-size: 0.7rem">
                                <span style="
                                    display: inline-block;
                                    border-radius: 50%;
                                    width: 7px;
                                    height: 7px;
                                    background-color: grey;
                                    margin-bottom: 1px;
                                "></span> User defined rule
                            </div>
                        </div>
                        <div class="row m-2">
                            <div class="col-sm-6 col-md-6">
                                <h6>Level 1 Alerts</h6>
                                <div class="form-check">
                                    <input
                                        id="level-1-sms"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_1?.sms?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_1?.sms)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 1, "sms", this.alert_rules?.level_1?.sms)}
                                    />
                                    <label for="level-1-sms" class="form-check-label">SMS</label>
                                    ${this.alert_rules?.level_1?.sms?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-1-email"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_1?.email?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_1?.email)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 1, "email", this.alert_rules?.level_1?.email)}
                                    />
                                    <label for="level-1-email" class="form-check-label">Email</label>
                                    ${this.alert_rules?.level_1?.email?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-1-app"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_1?.app?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_1?.app)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 1, "app", this.alert_rules?.level_1?.app)}
                                    />
                                    <label for="level-1-app" class="form-check-label">In app</label>
                                    ${this.alert_rules?.level_1?.app?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                            </div>
                                
                            <div class="col-sm-6 col-md-6">
                                <h6 class="mt-1">Level 2 Alerts</h6>
                                <div class="form-check">
                                    <input
                                        id="level-2-sms"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_2?.sms?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_2?.sms)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 2, "sms", this.alert_rules?.level_2?.sms)}
                                    />
                                    <label for="level-2-sms" class="form-check-label">SMS</label>
                                    ${this.alert_rules?.level_2?.sms?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-2-email"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_2?.email?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_2?.email)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 2, "email", this.alert_rules?.level_2?.email)}
                                    />
                                    <label for="level-2-email" class="form-check-label">Email</label>
                                    ${this.alert_rules?.level_2?.email?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-2-app"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?disabled=${this.alert_rules?.level_2?.app?.ignore_user_id}
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_2?.app)}
                                        @change=${(e) => this.toggleRule(e.target.checked, 2, "app", this.alert_rules?.level_2?.app)}
                                    />
                                    <label for="level-2-app" class="form-check-label">In app</label>
                                    ${this.alert_rules?.level_2?.app?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                            </div>
                                
                            <div class="col-sm-6 col-md-6">
                                <h6 class="mt-1">Level 3 Alerts</h6>
                                <div class="form-check">
                                    <input
                                        id="level-3-sms"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_3?.sms)}
                                        ?disabled=${this.alert_rules?.level_3?.sms?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 3, "sms", this.alert_rules?.level_3?.sms)}
                                    />
                                    <label for="level-3-sms" class="form-check-label">SMS</label>
                                    ${this.alert_rules?.level_3?.sms?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-3-email"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_3?.email)}
                                        ?disabled=${this.alert_rules?.level_3?.email?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 3, "email", this.alert_rules?.level_3?.email)}
                                    />
                                    <label for="level-3-email" class="form-check-label">Email</label>
                                    ${this.alert_rules?.level_3?.email?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="level-3-app"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.level_3?.app)}
                                        ?disabled=${this.alert_rules?.level_3?.app?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 3, "app", this.alert_rules?.level_3?.app)}
                                    />
                                    <label for="level-3-app" class="form-check-label">In app</label>
                                    ${this.alert_rules?.level_3?.app?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <h6 class="mt-1">Patient SMS Alerts</h6>
                                <div class="form-check">
                                    <input
                                        id="patient-sms-sms"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.patient_sms?.sms)}
                                        ?disabled=${this.alert_rules?.patient_sms?.sms?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 4, "sms", this.alert_rules?.patient_sms?.sms)}
                                    />
                                    <label for="patient-sms-sms" class="form-check-label">SMS</label>
                                    ${this.alert_rules?.patient_sms?.sms?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="patient-sms-email"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.patient_sms?.email)}
                                        ?disabled=${this.alert_rules?.patient_sms?.email?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 4, "email", this.alert_rules?.patient_sms?.email)}
                                    />
                                    <label for="patient-sms-email" class="form-check-label">Email</label>
                                    ${this.alert_rules?.patient_sms?.email?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                                <div class="form-check">
                                    <input
                                        id="patient-sms-app"
                                        type="checkbox"
                                        class="form-check-input"
                                        ?checked=${this.isRuleActive(this.alert_rules?.patient_sms?.app)}
                                        ?disabled=${this.alert_rules?.patient_sms?.app?.ignore_user_id}
                                        @change=${(e) => this.toggleRule(e.target.checked, 4, "app", this.alert_rules?.patient_sms?.app)}
                                    />
                                    <label for="patient-sms-app" class="form-check-label">In app</label>
                                    ${this.alert_rules?.patient_sms?.app?.user_id ? html`
                                    <span style="
                                        display: inline-block;
                                        border-radius: 50%;
                                        width: 7px;
                                        height: 7px;
                                        background-color: grey;
                                        margin-bottom: 3px;
                                    "></span>` : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary modal-cancel-btn" data-bs-dismiss="modal">
                            Done
                        </button>
                    </div>
                </div>
            </div>
            `;
        }

        this.render();
        this.init();
    }

    async init() {
        this.rules_service = new NotificationRules(
            getCurrentClientId(),
            this.caregiver.id,
            directus.items("notification_rule"),
        );

        this.alert_rules = await this.rules_service.loadRuleMap();
    }

    render() {
        if (!this.template) return;
        if (!this.caregiver) return;
        if (!this.client_access) return;

        render(this.template(), this);
    }

    isRuleActive(rule) {
        if (!rule) return false;
        if (rule.ignore_delivery_method) return false;
        return true;
    }

    async toggleRule(checked, level, delivery_method, rule) {
        this._createUserRule(level, delivery_method, !checked);
    }

    async resetToDefault() {
        this.alert_rules = await this.rules_service.resetUserRules();
        this.querySelectorAll("input[type=checkbox]").forEach((e) => {
            const split = e.id.split("-");
            const [_title, level, delivery_method] = split;
            if (this.alert_rules["level_" + level][delivery_method]) {
                e.checked = true;
            } else {
                e.checked = false;
            }
        });
    }

    async _createUserRule(level, delivery_method, ignore_delivery_method) {
        this.alert_rules = await this.rules_service.addUserRule(level, delivery_method, ignore_delivery_method);
    }
}

customElements.define("app-settings-careteam-notifications", AppSettingsCareTeamNotifications);
