import { html, LitElement } from "lit";
import "shared/components/app-reviews-dashboard";
import { getCurrentClientId } from "shared/lib/lib-user";
import ClientSettings from "shared/lib/lib-client-settings";

export default class SceneReviewsDashboard extends LitElement {
    static get properties() {
        return {
            placeId: { type: String },
            loading: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.placeId = null;
        this.loading = true;
    }

    createRenderRoot() {
        return this;
    }

    async firstUpdated() {
        const clientId = getCurrentClientId();
        const settings = await ClientSettings.getSettings(clientId);
        this.placeId = settings?.ALL?.google_place_id?.value;
        this.loading = false;
        this.requestUpdate();
    }

    render() {
        if (this.loading) {
            return html`
                <div class="text-center p-4">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            `;
        }

        if (!this.placeId) {
            return html`
                <div class="alert alert-warning" role="alert">
                    <h4 class="alert-heading">No Google Business Profile Connected</h4>
                    <p>To view review analytics, you need to connect your Google Business Profile first.</p>
                    <hr>
                    <p class="mb-0">
                        <a href="/client/settings" class="alert-link">
                            Go to Company Settings
                        </a>
                        to connect your profile.
                    </p>
                </div>
            `;
        }

        return html`
            <div class="container-fluid">
                <div class="row mb-4">
                    <div class="col">
                        <h2 class="h4 mb-0">Review Analytics</h2>
                        <p class="text-muted small mb-0">
                            Track and analyze your Google Business Profile reviews
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <app-reviews-dashboard></app-reviews-dashboard>
                    </div>
                </div>
            </div>
        `;
    }
}

customElements.define("scene-reviews-dashboard", SceneReviewsDashboard);
