import { html, render } from "lit";
import { differenceInMinutes, differenceInHours, differenceInCalendarDays } from "date-fns";
import directus from "../lib/lib-directus";
import { getCurrentClientId } from "../lib/lib-user";
import AppSMSListModal from "./patient/app-sms-list-modal";
import AppCalendarViewModal from "./availability/app-calendar-view-modal";

/**
 * @fires AppUnreadSMSList#smsupdated when sms are fetched from the server
 */
export default class AppUnreadSMSList extends HTMLElement {
    constructor() {
        super();
        this.unread_sms = [];

        const five_minutes_ms = 60 * 1 * 1000;
        this.intervalId = setInterval(this.fetchUnreadSMS.bind(this), five_minutes_ms);
    }
    get unread_sms_count() {
        return this.unread_sms ? this.unread_sms.filter(sms => sms.status_reason === null).length : 0;
    }

    /**
     * Queries directus for notifications received by the current user.
     * @todo auth token lost when refreshing certain pages (e.g. patient details)
     */

    async fetchUnreadSMS() {
        const sms_result = await directus.items("patient_communication").readByQuery({
            filter: {
                communication_type: "sms",
                caller_type: "patient",
                //status_reason: { _null: true },
                client_id: getCurrentClientId()
            },
            fields: ["*", "patient_id.id", "patient_id.first_name", "patient_id.last_name"],
            sort: ['-scheduled_date']
        });
        this.unread_sms = sms_result.data;
        this.render();
        this.dispatchEvent(new CustomEvent("smsupdated"));
    }

    formatNotificatonTime(timestamp) {
        const now = new Date();
        const timestamp_date = new Date(timestamp);
        const time_difference_mins = differenceInMinutes(now, timestamp_date);
        const time_difference_hrs = differenceInHours(now, timestamp_date);
        const time_difference_days = differenceInCalendarDays(now, timestamp_date);

        if (time_difference_mins < 60) return time_difference_mins + "m ago";
        else if (time_difference_hrs < 24) return time_difference_hrs + "h ago";
        else return time_difference_days + "d ago";
    }

    connectedCallback() {
        this.template = () => {
            const sms_rows = this.unread_sms.map((sms) => {
                const patientName = (sms.patient_id?.first_name ?? "") + " " + (sms.patient_id?.last_name ?? "");
                return html`
                <li class="list-group-item ${sms.status_reason}" style="display: flex; flex-direction: column;">
                    <div class="d-flex" style="width: 100%; align-items: flex-start; margin-bottom: 6px;">
                        <h6 class="patient-button" @click=${() => this.showCalendar(sms.patient_id)} style="cursor: pointer;">
                            ${patientName}
                        </h6>
                        <span style="margin-left: auto; font-size: 12px; font-style: italic; white-space: nowrap; padding-left: 12px; line-height: 15px;">
                            ${this.formatNotificatonTime(sms.scheduled_date)}
                        </span>
                    </div>
                    <div style="font-size: 13px; cursor: pointer;" @click=${(_e) => this.handleSMSClick(sms)}>
                        ${sms.sms_message}
                    </div>
                    ${sms.status_reason == "read" ? '' : html`
                    <button
                        type="button"
                        class="btn btn-outline-danger btn-sm sms-read-all-button sms-read-all-button--small"
                        @click=${(e) => this.handleMarkAsRead(e, sms)}>
                        Mark as Read
                    </button>
                    `}

                </li>`;
            });
            return html`
                <style>
                    #sms-header {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                        box-shadow: 0 4px 2px -2px rgba(0, 39, 77, 0.08);
                        margin-bottom: 4px;
                        padding: 8px 12px;
                        height: 40px;
                    }
                    #sms-header h5 {
                        margin-bottom: unset;
                    }
                    #sms-container {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-top: none;
                    }
                    #sms-container li {
                        border-left: none;
                        border-right: none;
                        padding: 12px 16px 18px 16px;
                    }
                    #sms-container li:first-child {
                        border-top: none;
                        padding-top: 8px;
                    }
                    .sms-read-all-button {
                        color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                        height: 20px;
                        display: flex;
                        align-items: center;
                    }
                    .sms-read-all-button--small {
                        position: relative;
                        margin-left: auto;
                        top: 6px;
                        color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                        height: 20px;
                        display: flex;
                        align-items: center;
                    }
                    .sms-read-all-button:hover {
                        color: var(--t-color-white);
                        background-color: var(--t-color-danger);
                    }
                    .patient-button {
                        text-transform: uppercase;
                        font-size: 12px;
                        margin: unset;
                        font-weight: 600;
                        align-self: center;
                        line-height: 15px;
                        text-decoration: underline;
                    }
                    .patient-button:hover {
                        background-color: lightgray;
                    }
                </style>
                ${sms_rows.length
                    ? html`<div class="d-flex" id="sms-header">
                          <h5>Recent Messages</h5>
                          <button
                              class="btn btn-sm btn-outline-danger sms-read-all-button"
                              style="margin-left: auto"
                              @click=${(e) => this.handleAllMarkAsRead(e)}>
                              All Mark as Read
                          </button>
                      </div>`
                    : ""}
                <ul class="list-group" id="sms-container" style="max-height: 500px; overflow-y: auto;">
                    ${sms_rows.length
                    ? sms_rows
                    : html`<div style="height: 200px; display: flex; align-items: center; justify-content: center;">
                              No unread message!
                          </div>`}
                </ul>
            `;
        };

        this.fetchUnreadSMS();

        Object.assign(this.style, {
            width: "345px",
            display: "block",
        });
    }

    disconnectedCallback() {
        clearInterval(this.intervalId);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
    addNewSMS(smsData) {
        this.unread_sms.unshift(smsData);
        this.render();
        this.dispatchEvent(new CustomEvent("smsupdated"));
    }


    async handleMarkAsRead(e, sms) {
        e.stopPropagation();
        await directus.items("patient_communication").updateOne(sms.id, {
            status_reason: "read",
        });
        this.fetchUnreadSMS();
    }

    async handleAllMarkAsRead(e) {
        e.stopPropagation();
        const ids = this.unread_sms.map(sms => sms.id);
        await directus.items('patient_communication').updateMany(ids, {
            status_reason: "read",
        });
        this.fetchUnreadSMS();
    }

    async handleSMSClick(sms) {
        const sms_result = await directus.items("patient_communication").readByQuery({
            filter: {
                communication_type: "sms",
                patient_id: sms.patient_id.id
            },
            sort: ['scheduled_date']
        });

        // Simple patient fetch
        const patient = await directus.items('patient').readOne(sms.patient_id.id);

        const allSMS = sms_result.data;
        const modal = new AppSMSListModal(allSMS, sms, true, patient);
        await modal.showModal();
        setTimeout(this.fetchUnreadSMS, 1000);
    }

    async showCalendar(patient) {
        const modal = new AppCalendarViewModal(patient);
        await modal.showModal();
    }
}

customElements.define("app-unread-sms-list", AppUnreadSMSList);
