import { parse, format, endOfMonth, eachDayOfInterval } from "date-fns";

import { html, render } from "lit";
import directus from "../../lib/lib-directus";
import DataDefinition from "../../lib/lib-data-definition";
import AppAvailabilityDetail from "./app-availability-detail";
import AppTaskAdHocModal from "../app-task-ad-hoc-modal";
import AppTaskSelfCheckModal from "../app-task-self-check-modal";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import { convertUtcToLocalTime, convertUtcToLocal, formatToLocalTime } from "../../lib/lib-date";
import AppPatientActivityModal from "../../components/patient/app-patient-activity-modal";
import AppSMSListModal from "../../components/patient/app-sms-list-modal";
import AppCalendarModal from "../app-calendar-modal";
export default class AppCalendarView extends HTMLElement {
    set patient(value) {
        this._patient = value;
        this.sms_id = null;
    }

    get patient() {
        return this._patient;
    }

    set type(value) {
        if (value)
            this._type = value;
    }

    get type() {
        return this._type;
    }
    set calendar_items(values) {
        this._calendar_items = values;
        this.render();
    }

    get calendar_items() {
        return this._calendar_items;
    }

    constructor() {
        super();
        const now = new Date();
        this.availabilities = null;
        this._calendar_items = [];
        this.start_date = new Date(now.getFullYear(), now.getMonth(), 1);
        this.end_date = endOfMonth(this.start_date);
        this.SMSes = [];



    }

    async connectedCallback() {
        this.render();
        // Delay to ensure the DOM is fully ready before initializing FullCalendar
        await new Promise(resolve => setTimeout(resolve, 0));
        this.patient_communication_definition = await DataDefinition.getDefinition("patient_communication");
        this.initializeFullCalendar();

    }
    initializeFullCalendar() {
        if (this.calendar) return;
        //console.log('this.calendar_items', this.calendar_items);
        const calendarEl = this.querySelector('#calendar');
        let isMobile = window.matchMedia("(max-width: 768px)").matches;
        let defaultView = isMobile ? 'listMonth' : 'dayGridMonth';
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin],
            views: {
                twoMonthView: {
                    type: "multiMonth",
                    duration: { months: 2 },
                    multiMonthMaxColumns: 2,
                    showNonCurrentDates: false,
                    fixedWeekCount: false,
                    buttonText: '2 Months',

                } // this goes in the buttno sectrion ,twoMonthView
            },
            initialView: defaultView,
            height: 630,
            timeZone: 'local',
            //timeZone: 'America/ New_York',
            editable: true,
            droppable: true,
            listDayFormat: { weekday: 'long', month: 'short', day: 'numeric' },
            headerToolbar: {
                left: 'prev today next',
                center: '',
                right: 'dayGridMonth,multiMonthYear,listMonth,twoMonthView'
            },

            dayMaxEvents: 6,
            eventDrop: this.handleEventDrop.bind(this),
            eventClick: (info) => {
                const eventType = info.event.extendedProps.type;
                if (eventType === 'Unavailability') {
                    this.openAvailabilityDetail(info.event.extendedProps.source_record, false, eventType);
                    console.log('availability', info.event.extendedProps.source_record);
                } else {
                    this.showEventDetails(info.event);
                }
            },
            dateClick: (info) => {
                this.handleDateClick(info);
            },

            datesSet: async (info) => {
                this.start = info.start;
                this.end = info.end;
                this.lastStartStr = info.startStr;
                this.lastEndStr = info.endStr;

                await this.updateReport(this.start, this.end);

            },
            windowResize: () => {
                const newWindowHeight = window.innerHeight;
                const newCalendarTop = calendarEl.getBoundingClientRect().top;
                const newCalendarHeight = Math.max(newWindowHeight - newCalendarTop - 10, 450);
                this.calendar.setOption('height', newCalendarHeight);
            },
            eventContent: (arg) => { // Use an arrow function here
                //console.log('arg', arg);
                //console.log('type', arg.event.extendedProps.type);
                var titleContainer = document.createElement('div');
                titleContainer.classList.add('custom-event-title');
                // Right part of the title for 'self' checkpoints
                var rightPart = document.createElement('div');
                rightPart.classList.add('title-right');
                // Left part of the title
                var leftPart = document.createElement('div');
                leftPart.classList.add('title-left');
                // 2nd row part of the title
                var secondPart = document.createElement('div');
                secondPart.classList.add('title-second-row');

                leftPart.textContent = arg.event.title;
                rightPart.textContent = arg.event.extendedProps.clinician;
                var formattedTime = this.calendar.formatDate(arg.event.start, {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: true
                });
                if (arg.event.extendedProps.task_type) {
                    leftPart.textContent = arg.event.extendedProps.title_left;
                    rightPart.textContent = arg.event.extendedProps.title_right;
                    secondPart.textContent = arg.event.extendedProps.clinician;
                    console.log('arg.event.extendedProps.status', arg.event.extendedProps.compare_variable);
                    if (arg.event.extendedProps.compare_variable == "unable_to_complete") {
                        secondPart.textContent = arg.event.extendedProps.status_reason;

                    } else if (arg.event.extendedProps.task_type === 'prn') {
                        rightPart.textContent = arg.event.extendedProps.title_right;
                        leftPart.textContent = arg.event.extendedProps.title_left;
                        secondPart.textContent = arg.event.title;

                    } else if (arg.event.extendedProps.task_type === 'self') {
                        rightPart.textContent = formattedTime;
                        leftPart.textContent = arg.event.extendedProps.title_left;
                        secondPart.textContent = arg.event.title;
                    }
                } else if (arg.event.extendedProps.visit_type) {
                    leftPart.textContent = arg.event.extendedProps.title_left;
                    rightPart.textContent = arg.event.extendedProps.title_right;
                    secondPart.textContent = arg.event.extendedProps.visit_status;

                } else if (arg.event.extendedProps.type == 'Care Team Call') {
                    leftPart.textContent = arg.event.extendedProps.type;
                    rightPart.textContent = formattedTime;
                    secondPart.textContent = arg.event.extendedProps.status_reason;

                } else if (arg.event.extendedProps.type == "Status History") {
                    leftPart.textContent = arg.event.title;
                    //rightPart.textContent = formatToLocalTime(arg.event.start);
                    secondPart.textContent = arg.event.extendedProps.reason;

                } else if (arg.event.extendedProps.type == "Unavailability") {
                    rightPart.textContent = arg.event.extendedProps.title_right;
                    leftPart.textContent = arg.event.extendedProps.title_left;
                    secondPart.textContent = arg.event.title;
                }
                else {
                    rightPart.textContent = formattedTime;
                    leftPart.textContent = arg.event.extendedProps.type;
                    secondPart.textContent = arg.event.title;
                }


                titleContainer.appendChild(leftPart);
                titleContainer.appendChild(rightPart);

                if (secondPart.textContent) {
                    titleContainer.classList.add('has-second-part'); // Add class if secondPart has content
                    titleContainer.appendChild(secondPart)
                }


                return { domNodes: [titleContainer] };

            },
            eventDidMount: (info) => {
                if (this.calendar.view.type === 'dayGridMonth') {
                    const monthTitle = parse(this.calendar.view.title.split(' ')[0], 'MMMM', new Date());
                    const month = monthTitle.getMonth();
                    const monthDropdown = this.querySelector('#c-month');
                    monthDropdown.value = month;
                }

                if (this.calendar.view.type === 'listMonth') {
                    let source_record = info.event.extendedProps.source_record;
                    let thead = document.querySelector('.fc-list-table thead');

                    if (thead) {
                        let headers = thead.querySelector('tr');
                        headers.innerHTML = `
                            <th class="a-datetime">Date & Day</th>
                            <th class="a-name">Name</th>
                            <th class="a-reason">Reason</th>
                            <th class="a-doc>With</th>
                            <th class="a-spec">Specialty</th>
                            <th class="a-appt">Appointment Time</th>
                            <th class="a-notes">Notes</th>
                        `;
                    }

                    // Modify the day headers to include the new headers
                    let dayHeaders = Array.from(document.querySelectorAll('tr[data-date] th[scope="colgroup"]'));
                    dayHeaders.forEach(header => {
                        if (!header.parentElement.querySelector('.custom-day-header')) {
                            let headers;

                            if (this.type == "all") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-type', text: 'Type', colspan: 1 },
                                ];
                            } if (this.type == "communication") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-username', text: 'User Name', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date/Time', colspan: 1 },
                                    { class: 'custom-day-header a-type', text: 'Type', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                ];
                            } else if (this.type == "calls") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'From', colspan: 2 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    { class: 'custom-day-header a-duration', text: 'Duration', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 },
                                    { class: 'custom-day-header a-reason', text: 'Disposition', colspan: 1 },
                                ];
                            } else if (this.type == "visits") {
                                headers = [
                                    { class: 'custom-day-header a-type', text: 'Visit Type', colspan: 2 },
                                    { class: 'custom-day-header a-name', text: 'Clinician', colspan: 1 },

                                    { class: 'custom-day-header a-reason', text: 'Visit Status', colspan: 1 },
                                    { class: 'custom-day-header a-sync', text: 'Last Sync', colspan: 1 }
                                ];
                            } else if (this.type == "checkpoints") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Checkpoint', colspan: 2 },
                                    { class: 'custom-day-header a-title', text: 'Clinician', colspan: 1 },
                                    { class: 'custom-day-header a-status', text: 'Status', colspan: 1 },
                                    { class: 'custom-day-header a-week', text: 'Episode', colspan: 1 },
                                    { class: 'custom-day-header a-alerts', text: 'Alertsss', colspan: 1 },

                                ];


                            } else if (this.type == "notes") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Added By', colspan: 2 },
                                    { class: 'custom-day-header a-addedby', text: 'Question', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 }
                                ];
                            } else if (this.type == "availability") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Name', colspan: 2 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                    { class: 'custom-day-header a-date', text: 'Date', colspan: 1 },
                                    { class: 'custom-day-header a-time', text: 'Time', colspan: 1 },
                                    { class: 'custom-day-header a-note', text: 'Note', colspan: 1 }
                                ];
                            } else if (this.type == "status_history") {
                                headers = [
                                    { class: 'custom-day-header a-name', text: 'Status Change', colspan: 2 },
                                    { class: 'custom-day-header a-reason', text: 'Reason', colspan: 1 },
                                    { class: 'custom-day-header a-notes', text: 'Notes', colspan: 1 }
                                ];
                            }
                            let lastHeader = header;
                            headers.forEach(h => {
                                let newHeader = document.createElement('th');
                                newHeader.className = h.class;
                                newHeader.textContent = h.text;

                                if (h.colspan && h.colspan > 1) {
                                    newHeader.setAttribute('colspan', h.colspan);
                                }

                                header.parentElement.insertBefore(newHeader, lastHeader.nextSibling);
                                lastHeader = newHeader;
                            });
                        }
                    });

                    let redundantDayLabels = Array.from(document.querySelectorAll('.fc-list-day-side-text'));
                    redundantDayLabels.forEach(label => label.remove());
                    let dateHeaderColgroup = document.querySelector('tr[data-date] th[scope="colgroup"]');
                    if (dateHeaderColgroup) {
                        // Change the scope to just a single column
                        dateHeaderColgroup.setAttribute('scope', 'col');

                        // Remove the colspan attribute if it exists
                        if (dateHeaderColgroup.hasAttribute('colspan')) {
                            dateHeaderColgroup.removeAttribute('colspan');
                        }
                    }

                    let titleCell = info.el.querySelector('.fc-list-event-title');

                    if (titleCell) {
                        if (this.type == "all") {
                            let typeCell = document.createElement('td');
                            typeCell.innerHTML = `${info.event.extendedProps.type}`;
                            titleCell.parentElement.insertBefore(typeCell, titleCell.nextSibling);

                        } else if (this.type == "communication") {
                            let userNameCell = document.createElement('td');
                            userNameCell.innerHTML = `${source_record.user_created?.first_name + " " + source_record.user_created?.last_name}`;
                            titleCell.parentElement.insertBefore(userNameCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${format(convertUtcToLocalTime(source_record.date_created), "MM/dd/y 'at' h:mm aaa")}`;
                            titleCell.parentElement.insertBefore(dateCell, userNameCell.nextSibling);

                            let typeCell = document.createElement('td');
                            typeCell.innerHTML = `${this.patient_communication_definition?.getChoiceLabel('communication_type', source_record.communication_type)}`;
                            titleCell.parentElement.insertBefore(typeCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes != null ? source_record.notes : ""}`;
                            titleCell.parentElement.insertBefore(noteCell, typeCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.status_reason != null ? source_record.status_reason : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, noteCell.nextSibling);

                        } else if (this.type == "calls") {

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, titleCell.nextSibling);

                            let durationCell = document.createElement('td');
                            durationCell.innerHTML = `${source_record.call_duration > 90 ? (Math.round(source_record.call_duration / 60) + " minutes") : ((source_record.call_duration || 0) + " seconds")}`;
                            titleCell.parentElement.insertBefore(durationCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes != null ? source_record.notes : ""}`;
                            titleCell.parentElement.insertBefore(noteCell, durationCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.status_reason != null ? source_record.status_reason : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, noteCell.nextSibling);

                        } else if (this.type == "visits") {

                            //let typeCell = document.createElement('td');
                            //typeCell.innerHTML = `${source_record.visit_type}`;
                            //titleCell.parentElement.insertBefore(typeCell, titleCell.nextSibling);

                            let userNameCell = document.createElement('td');
                            userNameCell.innerHTML = `${source_record.careteam_member_id.first_name?.[0] + '.' || ''} ${source_record.careteam_member_id.last_name || ''}`;
                            titleCell.parentElement.insertBefore(userNameCell, titleCell.nextSibling);

                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.visit_status != null ? source_record.visit_status : ""}`;
                            titleCell.parentElement.insertBefore(reasonCell, userNameCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${format(convertUtcToLocalTime(source_record.last_sync_date), "MM/dd/y 'at' h:mm aaa")}`;
                            titleCell.parentElement.insertBefore(dateCell, reasonCell.nextSibling);



                        } else if (this.type == "checkpoints") {
                            let titleCell = info.el.querySelector('.fc-list-event-title');
                            if (titleCell) {
                                titleCell.classList.add('nowrap');
                                titleCell.style.minWidth = '150px';
                                const properties = ['clinician', 'status', 'episode', 'alerts'];

                                properties.forEach(prop => {
                                    const cell = document.createElement('td');
                                    if (prop === 'status') {
                                        // Interpret the HTML string for status
                                        cell.innerHTML = info.event.extendedProps[prop] || 'No information provided';
                                    } else if (prop === 'alerts') {
                                        cell.innerHTML = info.event.extendedProps[prop] || 'No information provided';
                                    } else {
                                        cell.textContent = info.event.extendedProps[prop] || 'No information provided';
                                    }
                                    titleCell.parentElement.appendChild(cell);
                                });
                            }

                        } else if (this.type == "notes") {
                            let addedByCell = document.createElement('td');
                            addedByCell.innerHTML = `${source_record.question}`;
                            titleCell.parentElement.insertBefore(addedByCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, addedByCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.content}`;
                            titleCell.parentElement.insertBefore(noteCell, dateCell.nextSibling);

                        } else if (this.type == "availability") {
                            let reasonCell = document.createElement('td');

                            if (source_record.reason === "dr_appointment")
                                reasonCell.innerHTML = "Doctor's Appointment";
                            else if (source_record.reason === "travel")
                                reasonCell.innerHTML = "Travel";
                            else if (source_record.reason === "dialysis")
                                reasonCell.innerHTML = "Dialysis";
                            else if (source_record.reason === "other")
                                reasonCell.innerHTML = `Other: ${source_record.reason_other}`;

                            titleCell.parentElement.insertBefore(reasonCell, titleCell.nextSibling);

                            let dateCell = document.createElement('td');
                            dateCell.innerHTML = `${convertUtcToLocalTime(source_record.date_created).toLocaleDateString()}`;
                            titleCell.parentElement.insertBefore(dateCell, reasonCell.nextSibling);

                            let timeCell = document.createElement('td');
                            timeCell.innerHTML = `${`
                                from ${format(convertUtcToLocalTime(source_record.start_time), "M/d/yyyy hh:mm a")}
                                to ${format(convertUtcToLocalTime(source_record.end_time), "M/d/yyyy hh:mm a")}
                                ${source_record.dr_appt_doctor ? `
                                    with ${source_record.dr_appt_doctor}
                                    ${source_record.dr_appt_specialty ? `(${source_record.dr_appt_specialty})` : ""}
                                ` : ""}
                            `}`;
                            titleCell.parentElement.insertBefore(timeCell, dateCell.nextSibling);

                            let noteCell = document.createElement('td');
                            noteCell.innerHTML = `${source_record.notes}`;
                            titleCell.parentElement.insertBefore(noteCell, timeCell.nextSibling);

                        } if (this.type == "status_history") {
                            let reasonCell = document.createElement('td');
                            reasonCell.innerHTML = `${source_record.reason}`;
                            titleCell.parentElement.insertBefore(reasonCell, titleCell.nextSibling);

                            let notesCell = document.createElement('td');
                            notesCell.innerHTML = `${source_record.notes}`;
                            titleCell.parentElement.insertBefore(notesCell, reasonCell.nextSibling);
                        }

                    }
                }
            },
        });

        this.calendar.render();
        //this.calendar.setOption('height', initialCalendarHeight - 10);
        //console.log('initializeFullCalendar this cal: ', this.cal);
    }

    render() {
        // console.log('render: ', this.start);
        const yearsArray = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i);

        const template = html`
       
        <div class="cal-wrap staff-p-cal xxxx">
            <div class="col month-select">
                <div class="btn-group" style="align-items: center;">
                    <select id="c-month" class="form-select" style="width: auto; margin-left: 16px;" @change=${(evt) => this.handleMonthChange(evt)}>
                        ${Array.from({ length: 12 }, (_, i) => html`
                            <option value=${i} ?selected=${this.start_date.getMonth() === i}>
                                ${new Date(2000, i).toLocaleString('default', { month: 'long' })}
                            </option>
                        `)}
                    </select>

                    <select class="form-select" style="width: auto;" @change=${(evt) => this.handleYearChange(evt)}>
                        ${yearsArray.map(year => html`
                            <option value=${year} ?selected=${this.start_date.getFullYear() === year}>${year}</option>
                        `)}
                    </select>
                </div>
            </div>
            <div id="calendar"></div>
        </div>
    `;

        render(template, this);
        this.handleWindowResize();
    }

    handleWindowResize() {
        if (this.calendar) {
            const calendarRect = this.calendar.el.getBoundingClientRect();
            const distanceFromTop = calendarRect.top + 20;
            const availableHeight = window.innerHeight - distanceFromTop;
            this.calendar.setOption('height', availableHeight);
            //console.log('availableHeight', availableHeight);
        }
    }

    async openAvailabilityDetail(availability, editing, eventType = '') {
        const readonly = availability.reason == 'hh_visit' || eventType == 'Visits';
        console.log('availability', eventType, availability, editing);
        const modal = new AppAvailabilityDetail(availability, this.patient, editing, readonly);
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result) {
            this.updateReport();
        }
    }

    async showEventDetails(event) {
        const { source_record, type } = event.extendedProps;
        let modal;
        const isFutureEvent = this.isEventInFuture(source_record);

        if (type.includes("SMS")) {
            modal = new AppSMSListModal(this.SMSes, source_record);
            modal.patient_name = this.patient.first_name;
            console.log('type sms', type);

        } else if ((type === "Checkpoints" || type === "Care Team SMS") && isFutureEvent) {
            const readonly = false;
            const confirm_delete = true;
            modal = new AppPatientActivityModal(source_record, type, readonly, confirm_delete);
        } else {
            modal = new AppPatientActivityModal(source_record, type);
        }
        console.log('TYPE', type);
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result) {
            this.updateReport();
        }

    }


    isEventInFuture(source_record) {
        const eventDate = new Date(source_record.date_to_use || source_record.visit_date);
        return eventDate > new Date();
    }


    async openPRN(date) {
        const modal = new AppTaskAdHocModal();
        modal.patient = this.patient;
        modal.date = date;
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result) {
            this.updateReport();
        }
    }

    async openWellness(date, type) {
        const modal = new AppTaskSelfCheckModal();
        modal.patient = this.patient;
        modal.date = date;
        modal.type = type;
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result) {
            this.updateReport();
        }
    }

    async handleDateClick(info) {
        console.log(this.addFlag);
        if (!this.addFlag) {
            return;
        }
        const modal = new AppCalendarModal();
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result?.clicked) {
            if (result.type === "unavailability") {
                this.openAvailabilityDetail({ start_time: info.date, end_time: info.date }, true);
            } else if (result.type === "prn") {
                this.openPRN(info.date);
            } else if (result.type === "self" || result.type === "education" || result.type === "general" || result.type === "clinician") {
                this.openWellness(info.date, result.type);
            }
        }
    }

    handleMonthChange(evt) {
        this.start_date.setMonth(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getMonth();
    }

    handleYearChange(evt) {
        this.start_date.setFullYear(evt.target.value);
        this.calendar.gotoDate(this.start_date);
        // Set the dropdown value explicitly after changing the calendar date
        evt.target.value = this.start_date.getFullYear();
    }
    async updateReport() {
        //console.log('updateReport');

        const activityResult = await directus.transport.get("/vbh/patient/activities", {
            params: {
                filters: {
                    patient_id: this.patient.id
                }
            }
        });

        const patient_activities = activityResult.data;
        this.calendar_items = this.mapToCalendarItems(patient_activities);
        if (this.calendar) {
            this.calendar.removeAllEvents();
            this.calendar.addEventSource(this.calendar_items);
        }

        this.render();
        this.showSMSModal();
    }

    async showSMSModal() {
        console.log('smsid', this.sms_id);
        if (this.sms_id) {
            const sms = this.SMSes.find(item => item.id == this.sms_id);

            // Make sure we have a complete patient object with client_id
            let fullPatient = this.patient;
            if (!fullPatient?.client_id || typeof fullPatient.client_id === 'string') {
                try {
                    // Fetch complete patient data with client_id
                    fullPatient = await directus.items('patient').readOne(this.patient.id, {
                        fields: ['*', 'client_id.*', 'current_episode_id.*'],
                        deep: {
                            client_id: {
                                _limit: 1
                            }
                        }
                    });
                    console.log('Fetched complete patient with client_id:', fullPatient);
                } catch (error) {
                    console.error('Error fetching complete patient data:', error);
                    fullPatient = this.patient; // fallback to original
                }
            }

            const modal = new AppSMSListModal(this.SMSes, sms, false, fullPatient);
            this.sms_id = null;
            await modal.showModal();
        }
    }

    /**
     * Re-renders report with new date selections
     * @param {CustomEvent} e AppReportFilter#dateselect event
     */
    handleDateRangeChange(e) {
        const { start, end } = e.detail;
        this.start_date = start;
        this.end_date = end;
        this.updateReport();
    }

    /**
     * @param {Object[]} availabilities patient_availability data from server
     * @returns {CalendarItem[]} used for AppCalendarGrid and AppCalendarList
     */

    mapToCalendarItems(data) {
        //console.log('map data', data);
        let calendarItems = [];
        this.SMSes = [];


        if (data.patient_calls && (this.type === "calls" || this.type === "all")) {
            data.patient_calls.map(patient_call => {
                if (patient_call.communication_type == "phone_voice" && patient_call.caller_type == "staff") {
                    const calendarItem = {
                        start: patient_call.date_created,
                        description: `Duration: ${patient_call.call_duration >= 120 ? (Math.round(patient_call.call_duration / 60) + " Minutes") : (patient_call.call_duration + " Seconds")}`,
                        title: `${patient_call.user_created?.first_name?.[0] + '.' || ''} ${patient_call.user_created?.last_name || ''}`,
                        source_record: patient_call,
                        color: "var(--bs-primary)",
                        type: "Care Team Call",
                        className: 'cal-call cp-expander',
                        groupId: 'cal-call',
                        status_reason: patient_call.status_reason?.replace(/_/g, ' ') ?? 'No reason provided',
                    }
                    //TODO: Add condition for clinician calls and agency
                    //console.log('calendarItem from CALLS', calendarItem);
                    calendarItems.push(calendarItem);
                    //console.log('calendarItems from CALLS', patient_call.date_created);
                } else if (patient_call.communication_type == "sms") {
                    try {
                        const types = {
                            "general": "General",
                            "education": "Education",
                            "checkin": "Check-In",
                            "invite": "Invite",
                            "clinician": "Clinician",
                        };
                        let title = types[patient_call.notes] || "";
                        let smsClass = 'cal-sms cp-expander';
                        let fromWho = patient_call.caller_type == "staff" ? "Care Team SMS" : "Patient SMS";


                        if (patient_call.caller_type == "patient") {
                            title = "Patient";
                            smsClass = 'cal-sms cp-expander patient-sms';
                            if (patient_call.status_reason != "read") {
                                smsClass += " unread-sms";
                            }
                        }

                        const calendarItem = {
                            start: formatToLocalTime(patient_call.scheduled_date),
                            description: patient_call.sms_message,
                            title: title,
                            source_record: patient_call,
                            color: "var(--bs-primary)",
                            type: fromWho,
                            className: smsClass,
                            groupId: 'cal-sms',
                            editable: true
                        }

                        this.SMSes.push(patient_call);
                        calendarItems.push(calendarItem);
                    } catch (e) {
                        console.log(e);
                    }
                }
            })
        }
        if (data.communications && (this.type === "communication" || this.type === "all")) {
            data.communications.map(communication => {
                const calendarItem = {
                    start: communication.date_created,
                    end: communication.date_created,
                    description: `${communication.call_duration >= 120 ? (Math.round(communication.call_duration / 60) + " Minutes") : (communication.call_duration + " Seconds")}`,
                    title: `${communication.user_created.first_name?.[0] + '.' || ''} ${communication.user_created.last_name || ''}`,
                    source_record: communication,
                    color: "#000000",
                    type: "Agency Call",
                    className: 'cal-comm cp-expander',
                    groupId: 'cal-comm'
                }

                calendarItems.push(calendarItem);
            })
        }

        if (data.checkpoints && (this.type === "checkpoints" || this.type === "all")) {
            data.checkpoints.forEach(checkpoint => {
                let dotColor = '#9ac58f'; // Default dot color

                const alertsDetails = checkpoint.alerts?.length
                    ? checkpoint.alerts.map(alert => {
                        const notesDetails = alert.notes?.length
                            ? alert.notes.map(note => `<span style="padding-left:20px;" class='note-detail'>${note.content}</span>`).join('<br>')
                            : '<span class="no-notes">(no notes)</span>';
                        return `<div style="">
                    <span class="material-symbols-outlined" style=" color: #ca463d; font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48; font-size:14px;">
                        warning
                    </span>
                    <strong class='alert-subject'>${alert.subject}:</strong> ${notesDetails}`;
                    }).join('</div>')
                    : '<span class="no-alerts">No Alerts</span>'; // Display "No Alerts" if no alerts

                let statusText = checkpoint.status;
                let startTime = formatToLocalTime(checkpoint.date_to_use);
                let isAllDay = true;
                let cpTitle = checkpoint.title;
                let cpTLeft = checkpoint.title;
                let cpTRight = checkpoint.episode_id ? checkpoint.episode_id.id : 'No episode'
                let cpClass = 'cal-cp cp-expander';
                let cpTSecond = "";
                let compare_variable = "";
                let display = "auto";

                if (checkpoint.task_type == 'survey') {
                    startTime = checkpoint.date_to_use;
                    isAllDay = true;
                    //dotColor = '#b986ab';
                    //cpClass = 'cal-cp, cal-cp-prn cp-expander';
                    //cpTitle = checkpoint.form_design?.title;
                    cpTLeft = 'TeleCheck™';
                }

                if (checkpoint.task_type == 'prn') {
                    startTime = checkpoint.date_to_use;
                    isAllDay = true;
                    dotColor = '#b986ab';
                    cpClass = 'cal-cp, cal-cp-prn cp-expander';
                    cpTitle = checkpoint.form_design?.title;
                    cpTLeft = checkpoint.title + ' - TeleCheck™';
                }
                if (checkpoint.task_type == 'self') {
                    startTime = formatToLocalTime(checkpoint.date_to_use);
                    isAllDay = false;
                    dotColor = '#9d3056';
                    cpClass = 'cal-cp cal-cp-self cp-expander';
                    cpTitle = checkpoint.form_design?.title;
                    cpTLeft = 'BridgeCheck™';
                    cpTRight = startTime;
                    //display = "block";

                }

                if (statusText === 'unable_to_complete') {
                    dotColor = '#989898';
                    cpClass = 'cal-cp cal-cp-unable cp-expander';
                    const formattedReason = '<span class="note-detail">' + (checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided');
                    statusText = `<strong class="alert-subject">Unsuccessful:</strong> ${formattedReason}`;
                    status = 'Unsuccessful' + (checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided');
                    cpTSecond = checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided';
                    compare_variable = checkpoint.status;
                    console.log('compare_variable', compare_variable);

                } else if (statusText === 'active' && checkpoint.task_type !== 'self' && checkpoint.task_type !== 'prn') {
                    dotColor = '#74AAB7'; // pending color
                    statusText = '<strong class="alert-subject">Pending</strong>';

                } else if (statusText === 'complete' && checkpoint.alerts?.length) {
                    dotColor = '#9d3056';
                    statusText = '<strong class="alert-subject">Complete</strong>';
                    cpClass = cpClass ? `${cpClass} cal-cp-alert` : 'cal-cp cal-cp-alert cp-expander';
                }
                else if (statusText === 'complete') {
                    dotColor = '#9ac58f';
                    statusText = '<strong class="alert-subject">Complete</strong>';
                    cpClass = 'cal-cp cal-cp-complete cp-expander';
                }
                else if (statusText === 'archived') {
                    display = "none";
                }

                let checkpointItem = {
                    start: startTime,
                    title: cpTitle,
                    title_left: cpTLeft,
                    title_right: cpTRight,
                    title_second: cpTSecond,

                    compare_variable: compare_variable,
                    extendedProps: {
                        clinician: checkpoint.assigned_user ? `${checkpoint.assigned_user.first_name} ${checkpoint.assigned_user.last_name}` : 'Unknown',
                        date: checkpoint.date_to_use ? new Date(checkpoint.date_to_use).toLocaleString() : 'No date provided',
                        episode: checkpoint.episode_id ? checkpoint.episode_id.id : 'No episode',
                        alerts: alertsDetails, // string of all alerts and their nested notes
                        status: statusText, // status in HTML string
                        status_reason: checkpoint.status_reason?.replace(/_/g, ' ') ?? 'No reason provided',
                    },
                    color: dotColor,
                    task_type: checkpoint.task_type,
                    type: "Checkpoints",
                    source_record: checkpoint,
                    allDay: isAllDay,
                    display: display,
                    className: cpClass,
                    //backgroundColor: '#9ac58f',

                    display: "block",
                };

                const isEditable = checkpoint.status !== 'unable_to_complete';
                checkpointItem.editable = isEditable; // Move this line after checkpointItem is defined

                calendarItems.push(checkpointItem);
            });
        }


        if (data.notes && (this.type === "notes" || this.type === "all")) {
            data.notes.map(note => {
                const calendarItem = {
                    start: convertUtcToLocalTime(note.date_created),
                    // end: convertUtcToLocalTime(note.date_created),
                    description: `${note.content}`,
                    title: `${note.user_created?.first_name?.[0] + '.' || ''} ${note.user_created?.last_name || ''}`,
                    source_record: note,
                    color: "#c9b89b",
                    type: "Note",
                    //display: "block",
                    className: 'cal-note cp-expander',
                    groupId: 'cal-note'
                }

                calendarItems.push(calendarItem);
            })
        }

        if (data.unavailabilities && (this.type === "availability" || this.type === "all")) {
            data.unavailabilities.map(unavailability => {

                let reason = "";
                let startTime = unavailability.start_time;

                if (unavailability.reason === "dr_appointment") {
                    reason = "Doctor's Appointment";
                    //startTime = unavailability.dr_appt_datetime;
                } else if (unavailability.reason === "travel") {
                    reason = "Travel";
                } else if (unavailability.reason === "dialysis") {
                    reason = "Dialysis";
                } else if (unavailability.reason === "other") {
                    reason = `Other: ${unavailability.reason_other}`;
                }


                const calendarItem = {
                    start: startTime,
                    allDay: true,
                    title_left: "Unavailability",
                    title_right: reason,
                    end: unavailability.end_time,
                    description: `${unavailability.notes}`,
                    clinician: `${unavailability.user_created.first_name?.[0] + '.' || ''} ${unavailability.user_created.last_name || ''}`,
                    title: `${unavailability.user_created.first_name?.[0] + '.' || ''} ${unavailability.user_created.last_name || ''}`,
                    source_record: unavailability,
                    color: "#383838",
                    type: "Unavailability",
                    className: 'cal-avail cp-expander',
                    groupId: 'cal-avail'
                }
                calendarItems.push(calendarItem);
            })
        }
        if (data.agency_visits && (this.type === "visits" || this.type === "all")) {
            data.agency_visits.map(visit => {
                let visitClass = '';
                let vColor = '';
                if (visit.visit_description.toLowerCase().includes('visit')) {
                    visitClass = 'cal-visit';
                    vColor = '#584e6e';
                } else if (visit.visit_description.toLowerCase().includes('oasis')) {
                    visitClass = 'cal-oasis';
                    vColor = '#8ba7e7';
                } else {
                    visitClass = 'cal-other';
                    vColor = '#8778a8';
                }

                const calendarItem = {
                    start: convertUtcToLocalTime(visit.visit_date),
                    allDay: true,
                    title_left: visit.visit_description,
                    title_right: `${visit.careteam_member_id.first_name?.[0] + '.' || ''} ${visit.careteam_member_id.last_name || ''}`,
                    clinician: `${visit.careteam_member_id.first_name?.[0] + '.' || ''} ${visit.careteam_member_id.last_name || ''}`,
                    visit_status: visit.visit_status,
                    last_sync_date: visit.last_sync_date,
                    source_record: visit,
                    visit_type: visit.visit_type,
                    color: vColor,
                    type: "Visits",
                    className: visitClass + ' cp-expander',
                    groupId: visitClass
                }
                calendarItems.push(calendarItem);
            })
        }
        if (data.status_history && (this.type === "status_history" || this.type === "all")) {
            data.status_history.map(history => {

                const reason = history.reason
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');


                const calendarItem = {
                    start: convertUtcToLocalTime(history.date_changed),
                    color: "#242424",
                    allDay: true,
                    //title: `Status changed from ${history.old_status} to ${history.new_status}`,
                    title: `Status changed to ${history.new_status}`,
                    description: history.notes,
                    reason: reason,
                    source_record: history,
                    type: "Status History",
                    className: 'cal-status-history cp-expander',
                    groupId: 'cal-status-history'
                }
                calendarItems.push(calendarItem);
            })
        }
        // Add episodes first to create background layer
        if (data.episodes && (this.type === "all")) {
            data.episodes.forEach(episode => {
                const days = eachDayOfInterval({
                    start: convertUtcToLocalTime(episode.start_date),
                    end: convertUtcToLocalTime(episode.end_date),
                });

                const episode_id = episode.id;

                days.forEach((day, index) => {
                    calendarItems.push({
                        start: day,
                        end: day,
                        allDay: true,
                        backgroundColor: 'rgba(49, 103, 202, 0.18)',
                        color: '#000000',
                        eventTextColor: '#000000',
                        textColor: '#000000',
                        className: 'cal-episode',
                        display: 'background',
                        type: `E ${episode_id} Day ${index + 1}`,
                        //type: "Episode"
                    });
                });
            });
        }




        //console.log('calendarItems', calendarItems);
        return calendarItems;
    }
    // Implement the eventDrop handler
    handleEventDrop(info) {
        console.log('drop', info.event);

        const status = info.event.extendedProps.source_record.status;
        if (['unable_to_complete', 'complete', 'expired', 'failed'].includes(status)) {
            console.log('This event cannot be updated due to its status:', status);
            info.revert();
            return;
        }

        const newDate = info.event.start;
        const eventId = info.event.extendedProps.source_record.id; // Extract the correct ID
        console.log('Event ID:', eventId);

        this.updateScheduleItem(eventId, newDate, info.event.extendedProps.type, info);
    }

    async updateScheduleItem(eventId, newDate, eventType, info) {
        if (!eventId) {
            console.error('Event ID is missing or empty');
            info.revert();
            return;
        }

        try {
            if (eventType === 'Checkpoints' || eventType === 'Self Check') {
                await directus.items('task').updateOne(eventId, {
                    scheduled_date: newDate.toISOString(),
                });
            } else if (eventType.includes('SMS')) {
                await directus.items('patient_communication').updateOne(eventId, {
                    scheduled_date: newDate.toISOString(),
                });
            }

            this.updateReport();
        } catch (error) {
            console.error('Error updating event schedule:', error);
            info.revert();
        }
    }



}

customElements.define("app-calendar-view", AppCalendarView);
