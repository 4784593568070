import { html, render } from "lit";
import * as bootstrap from "bootstrap";
import { format } from "date-fns";
import AppTaskSelfCheckModal from "../app-task-self-check-modal";
import directus from "../../lib/lib-directus";

export default class AppSMSesLists extends HTMLElement {
    constructor() {
        super();
        this.patient_id = null;
        this.patient = null;
    }


    set SMSes(value) {
        this._SMSes = value
        this.render()
        setTimeout(() => this.scrollToBottom(), 0);
        console.log('sms setter', value)
    }

    get SMSes() {
        return this._SMSes

    }
    set creator(value) {
        this._creator = value
        if (value == "Service Account") {
            this._creator = "Automated"
        } else {
            this._creator = value
        }

        this.render()
        console.log('crator setter', value)
    }

    get creator() {
        return this._creator

    }
    set name(value) {
        this._name = value
        this.render()
        console.log('name setter', value)
    }

    get name() {
        return this._name

    }
    set patient_id(value) {
        this._patient_id = value
        //this.render()
        console.log('patient from sms list', value)
    }

    get patient_id() {
        return this._patient_id

    }


    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    #hub-smses-lists h6 {
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 26px;
                        color: #ffffff;
                        border-radius: 8px;
                        padding: 0 0 5px 40px;
                    }
                    #hub-smses-lists .hub-widget-row {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 100%;
                        font-size: 14px;
                        margin: 10px 0 0 0;
                    }
                    #hub-smses-lists .hub-widget-metric {
                        display: flex; 
                        flex-direction: column; 
                        align-items: center;
                        width: 126px;
                        height: 84px;
                        position: relative;
                    }
                    #hub-smses-lists .hub-widget-metric__value {
                        font-weight: 700;
                        line-height: 56px;
                        font-size: 40px;
                        color: var(--t-color-dark);
                        letter-spacing: 0.5px;
                    }
                    #hub-smses-lists .hub-widget-metric__label {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: var(--t-color-grey);
                        text-align: center;
                        white-space: pre-line;
                    }
                    .hub-chat {
                        width: 100%;
                        height: 656px;
                        overflow: auto;   
                    }                  
                    .hub-chat .hub-chat-history {
                        margin: 0px;
                        padding: 20px 25px 0 25px;
                    }
                    .hub-chat .hub-chat-history .hub-message-data.hub-align-right {
                        display: flex;
                        flex-direction: row-reverse;
                    }
                    .hub-chat .hub-chat-history .hub-message-data {
                        margin: 20px 0 15px 0;
                        color: white;
                    }
                    .hub-chat .hub-chat-history .hub-message-data-time {
                        color: #a8aab1;
                        padding-left: 6px;
                    }
                    .hub-chat .hub-chat-history .hub-message {
                        color: white;
                        padding: 10px 20px;
                        line-height: 26px;
                        font-size: 16px;
                        border-radius: 7px;
                        width: 90%;
                        position: relative;
                        margin-bottom: 5px;
                    }
                    .hub-chat .hub-chat-history .hub-message:after {
                        bottom: 100%;
                        left: 7%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-bottom-color: #86BB71;
                        border-width: 10px;
                        margin-left: -10px;
                    }
                    .hub-chat .hub-chat-history .hub-my-message {
                        background: #86BB71;
                    }
                    .hub-chat .hub-chat-history .hub-other-message {
                        background: #94C2ED;
                    }
                    .hub-chat .hub-chat-history .hub-other-message:after {
                        border-bottom-color: #94C2ED;
                        left: 93%;
                    }
                    .hub-align-right {
                        text-align: right;
                    }
                    .hub-float-right {
                        float: right;
                    }
                    .hub-clearfix:after {
                        visibility: hidden;
                        display: block;
                        font-size: 0;
                        content: " ";
                        clear: both;
                        height: 0;
                    }
                    .hub-smses-icon {
                        opacity: .5;
                        font-size: 23px;
                        font-weight: 100;
                        position: absolute;
                        left: 17px;
                        top: 21px;
                    }
                </style>
                <div 
                    id="hub-smses-lists" 
                    style="
                        height: 100%; 
                        display: flex; 
                        flex-direction: column;
                    "
                >
                    <div class="d-flex" id="hub-sms-list-hub-header">    
                        <h6><span class="hub-smses-icon material-symbols-outlined">phone_iphone</span>${this.name}</h6>
                        <button class="btn-light hub-sms-list-send" @click=${() => this.handleScheduleMessage()}>
                            Respond to Patient
                        </button>
                    </div>
                    
                        <div class="hub-widget-row">
                            <div class="hub-chat">
                                <div class="hub-chat-history">
                                    ${this.SMSes.map((sms, idx) => html`
                                    <div class="${sms.caller_type == "patient" ? "hub-in-message hub-clearfix" : ""}" attr-id="${idx}">
                                        <div class="hub-message-data ${sms.caller_type == "patient" ? "hub-align-right" : ""}">
                                            ${sms.caller_type != "patient" ? html`<span class="hub-message-data-name">${this._creator}</span>` : ""} 
                                            <span class="hub-message-data-time">${this.formatTime(sms.scheduled_date)}</span>
                                            ${sms.caller_type == "patient" ? html`<span class="hub-message-data-name">${this._name}</span>` : ""}
                                        </div>
                                        <div class="hub-message ${sms.caller_type == "patient" ? "hub-other-message hub-float-right" : "hub-my-message"}">
                                            ${sms.sms_message}
                                        </div>
                                    </div>
                                    `)}

                                    <div id="hub-sms-end" style="height:50px;"></div>

                                </div>
                            </div>
                        </div>
                    
                </div>
            `;
        };

        Object.assign(this.style, {
            background: "",
            boxShadow: "var(--t-box-shadow)",
            borderRadius: "8px",
            display: "block",
            padding: "20px 0px 0px 10px",

        });

        this.render();
        this.setTableHeight();
        this.setTableHeight();
        this.scrollToBottom();
        [...this.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach((el) => new bootstrap.Tooltip(el));
    }

    formatTime(time) {
        try {
            const localDate = new Date(time + 'Z'); // Ensure UTC format
            return format(localDate, "p, PP");
        } catch (error) {
            return time
        }
    }

    setTableHeight() {
        const chatContainer = this.querySelector('.hub-chat');
        if (chatContainer) {
            chatContainer.style.height = `${window.innerHeight - (chatContainer.getBoundingClientRect().top + 40)}px`;
        }
    }


    async scrollToBottom() {
        console.log('scrolling to bottom');
        const chatContainer = this.querySelector(".hub-chat");

        if (chatContainer) {
            console.log(`Container scrollHeight: ${chatContainer.scrollHeight}, scrollTop: ${chatContainer.scrollTop}`);

            const endElement = this.querySelector("#hub-sms-end");
            if (endElement) {
                console.log('Found #hub-sms-end, scrolling with smooth behavior');
                chatContainer.scrollTo({
                    top: chatContainer.scrollHeight,
                    behavior: 'smooth' // Smooth scrolling added here
                });
            } else {
                console.log('no end element');
            }
        } else {
            console.log('no chat container');
        }
    }



    async handleScheduleMessage() {
        const modal = new AppTaskSelfCheckModal();
        modal.patient = await this.getFullPatientData(this.patient_id);
        modal.type = 'general';
        modal.conversationContext = this.SMSes; // Pass the current conversation for context
        await modal.showModal();
        const result = await modal.onDidDismiss();
        if (result && result.confirmed) {
            await this.refreshSMSList(); // Refresh the SMS list after scheduling
            this.render();
        }
    }

    async getFullPatientData(patientId) {
        // Fetch complete patient data, including all required fields and nested client_id
        const patient = await directus.items('patient').readOne(patientId, {
            fields: ['*', 'client_id.*', 'engagement_specialist', 'current_episode_id.*'],
            deep: {
                client_id: {
                    _limit: 1
                }
            }
        });
        return patient;
    }



    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

}

customElements.define("app-smses-lists", AppSMSesLists);
