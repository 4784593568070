import { html, render } from 'lit';
import DataDefinition from '../../lib/lib-data-definition';
import AppFilterGroup from './app-filter-group';
import AppFilterSort from './app-filter-sort';
import AppFilterAggregate from './app-filter-aggregate';
import AppFilterAggregateGroup from './app-filter-aggregate-group';
import AppFilterUtil from './util/app-filter-util';
import AppFilterAdvancedModal from './app-filter-advanced-modal';
import AppFilterAggregateModal from './app-filter-aggregate-modal';
/**
 * @typedef {Object} FieldOption
 * @property {string} label - The display label for the option
 * @property {any} value - The value to store for the option
 */
/**
 * @typedef {Object} directusMetadata
 * @property {number} id 
 * @property {string} collection 
 * @property {string} field
 * @property {any} special
 * @property {string} interface
 * @property {Object[]} options
 * @property {any} display
 * @property {any} display_options
 * @property {boolean} readonly
 * @property {boolean} hidden
 * @property {number} sort
 * @property {string} width
 * @property {any} translations
 * @property {string} note
 */
/**
 * @typedef {Object} KnexSchema
 * @property {string} name
 * @property {string} table
 * @property {string} data_type
 * @property {any} default_value
 * @property {number} max_length
 * @property {number} numeric_precision
 * @property {number} numeric_scale
 * @property {boolean} is_nullable
 * @property {boolean} is_primary_key
 * @property {boolean} has_auto_increment
 * @property {string} foreign_key_column
 * @property {string} foreign_key_table
 * @property {string} comment
 */
/**
 * @typedef {Object} FieldConfig
 * @property {string} collection - The collection the field belongs to
 * @property {string} field - The database column name for the field
 * @property {string} label - The display label for the field
 * @property {string} type - The data type
 * @property {directusMetadata} meta - directus metadata
 * @property {KnexSchema} schema - The knex database schema object
 * @property {CollectionConfig} related_collection - Information about the collection this field is related to
 */
/**
 * @typedef {Object} CollectionConfig
 * @property {string} name - The name of the collection
 * @property {boolean} auto_configure - If the collection is a directus collection, setting this to true will load metadata automatically to configure the filters
 * @property {Promise} configured - A promise that resolves when an auto-configure is complete
 * @property {number} depth - How deep recursion should execute traversing relations. Defaults to 3
 * @property {FieldConfig[]} fields - Manual configuration for the fields to use in the filters
 */

/**
 * @typedef {Object} AppFilterConfig
 * @property {string[]} search_fields - A list of fields to search for the generic search query. Supports nested syntax like patient_id.first_name
 * @property {CollectionConfig} collection - The collection that will be filtered. If thiis is a directus collection auto_configure can be used to determine the types and values
 * 
 */

/**
 * @typedef {Object} FilterState
 * @property {string} user_search
 * @property {import('./app-filter-sort').SortColumn[]} sort
 * @property {import('./app-filter-group').FilterGroup} group
 * @property {import('./app-filter-aggregate').AggregateColumn[]} aggregate
 * @property {import('./app-filter-aggregate-group').AggregateGroupColumn[]} aggregate_group
 */

export const OPERATIONS = {
    _eq: { label: 'Equals', op: '_eq', description: 'Equal to' },
    _neq: { label: 'Doesn\'t equal', op: '_neq', description: 'Not equal to' },
    _lt: { label: 'Less than', op: '_lt', description: 'Less than' },
    _lte: { label: "Less than or equal to", op: "_lte", description: "Less than or equal to" },
    _gt: { label: "Greater than", op: "_gt", description: "Greater than" },
    _gte: { label: "Greater than or equal to", op: "_gte", description: "Greater than or equal to" },
    _in: { label: "Is one of", op: "_in", description: "Matches any of the values" },
    _nin: { label: "Is not one of", op: "_nin", description: "Doesn't match any of the values" },
    _null: { label: "Is null", op: "_null", description: "Is null" },
    _nnull: { label: "Isn't null", op: "_nnull", description: "Is not null" },
    _contains: { label: "Contains", op: "_contains", description: "Contains the substring" },
    _icontains: { label: "Contains (case insensitive))", op: "_icontains", description: "Contains the substring, ingnoring the case of letters" },
    _ncontains: { label: "Doesn't contain", op: "_ncontains", description: "Doesn't contain the substring" },
    _starts_with: { label: "Starts with", op: "_starts_with", description: "Starts with" },
    _nstarts_with: { label: "Doesn't start with", op: "_nstarts_with", description: "Doesn't start with" },
    _ends_with: { label: "Ends with", op: "_ends_with", description: "Ends with" },
    _nends_with: { label: "Doesn't end with", op: "_nends_with", description: "Doesn't end with" },
    _between: { label: "Is between", op: "_between", description: "Is between two values (inclusive)" },
    _nbetween: { label: "Isn't between", op: "_nbetween", description: "Is not between two values (inclusive)" },
    _empty: { label: "Is empty", op: "_empty", description: "Is empty (null or falsy)" },
    _nempty: { label: "Isn't empty", op: "_nempty", description: "Is not empty (null or falsy)" },
    _intersects: { label: "Intersects", op: "_intersects", description: "Value intersects a given point" },
    _nintersects: { label: "Doesn't intersect", op: "_nintersects", description: "Value does not intersect a given point" },
    _intersects_bbox: { label: "Intersects Bounding box", op: "_intersects_bbox", description: "Value is in a bounding box" },
    _nintersects_bbox: { label: "Doesn't intersect bounding box", op: "_nintersects_bbox", description: "Value is not in a bounding box" },
}

export const TYPE_OPERATIONS = {
    string: ['_eq', '_neq', '_contains', '_icontains', '_ncontains', '_starts_with', '_nstarts_with', '_ends_with', '_nends_with', '_in', '_nin', '_null', '_nnull', '_empty', '_nempty'],
    text: ['_eq', '_neq', '_contains', '_icontains', '_ncontains', '_starts_with', '_nstarts_with', '_ends_with', '_nends_with', '_in', '_nin', '_null', '_nnull', '_empty', '_nempty'],
    boolean: ['_eq', '_neq', '_null', '_nnull'],
    integer: ['_eq', '_neq', '_in', '_nin', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_between', '_nbetween'],
    bigInteger: ['_eq', '_neq', '_in', '_nin', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_between', '_nbetween'],
    float: ['_eq', '_neq', '_in', '_nin', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_between', '_nbetween'],
    decimal: ['_eq', '_neq', '_in', '_nin', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_between', '_nbetween'],
    geometry: ['_eq', '_neq', '_intersects', '_nintersects', '_intersects_bbox', '_nintersects_bbox', '_null', '_nnull'],
    timestamp: ['_eq', '_neq', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_in', '_nin', '_between', '_nbetween'],
    dateTime: ['_eq', '_neq', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_in', '_nin', '_between', '_nbetween'],
    date: ['_eq', '_neq', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_in', '_nin', '_between', '_nbetween'],
    time: ['_eq', '_neq', '_gt', '_gte', '_lt', '_lte', '_null', '_nnull', '_in', '_nin', '_between', '_nbetween'],
    uuid: ['_eq', '_neq', '_in', '_nin', '_null', '_nnull'],
}

export const AGGREGATE_FUNCTIONS = {
    count: {
        label: 'count'
    },
    countDistinct: {
        label: 'distinct count'
    },
    sum: {
        label: 'sum'
    },
    sumDistinct: {
        label: 'distinct sum'
    },
    avg: {
        label: 'average'
    },
    avgDistinct: {
        label: 'distinct average'
    },
    min: {
        label: 'minimum'
    },
    max: {
        label: 'maximum'
    }
}

export const TYPE_FUNCTIONS = {
    string: ['count', 'countDistinct'],
    text: ['count', 'countDistinct'],
    boolean: ['count', 'countDistinct'],
    integer: ['count', 'countDistinct', 'sum', 'sumDistinct', 'avg', 'avgDistinct', 'min', 'max'],
    bigInteger: ['count', 'countDistinct', 'sum', 'sumDistinct', 'avg', 'avgDistinct', 'min', 'max'],
    float: ['count', 'countDistinct', 'sum', 'sumDistinct', 'avg', 'avgDistinct', 'min', 'max'],
    decimal: ['count', 'countDistinct', 'sum', 'sumDistinct', 'avg', 'avgDistinct', 'min', 'max'],
    geometry: [],
    timestamp: ['count', 'countDistinct', 'min', 'max'],
    dateTime: ['count', 'countDistinct', 'min', 'max'],
    date: ['count', 'countDistinct', 'min', 'max'],
    time: ['count', 'countDistinct', 'min', 'max'],
    uuid: ['count', 'countDistinct'],
}

class AppFilter extends HTMLElement {
    set config(value) {
        if (!value.hasOwnProperty('auto_configure'))
            value.auto_configure = true;
        if (!value.hasOwnProperty('depth'))
            value.depth = 3;

        if (value == this._config)
            return;

        /** @type {AppFilterConfig} */
        this._config = value;
        this.init();
    }

    /** @type {AppFilterConfig} */
    get config() {
        return this._config;
    }

    set expanded(value) {
        this._expanded = value;
        this.render();
    }

    get expanded() {
        return this._expanded;
    }

    set enable_aggregate(value) {
        this._enable_aggregate = value;
        this.render();
    }

    get enable_aggregate() {
        return this._enable_aggregate;
    }

    set disable_sort(value) {
        this._disable_sort = value;
        this.render();
    }

    get disable_sort() {
        return this._disable_sort;
    }

    set show_filters(value) {
        this._show_filters = value;
        this.render();
    }

    get show_filters() {
        return this._show_filters;
    }

    set filter_state(value) {
        if (!value)
            value = {
                user_search: '',
                group: { type: "_and", filters: [], groups: [] },
            }
        this.user_search = value.user_search || '';
        this.group = value.group;
        this.sort = value.sort;
        this.aggregate = value.aggregate;
        this.aggregate_group = value.aggregate_group;
        this.render();
    }

    /** @type {FilterState} */
    get filter_state() {
        return {
            user_search: this.user_search,
            group: this.group,
            sort: this.sort,
            aggregate: this.aggregate,
            aggregate_group: this.aggregate_group
        }
    }

    set query(value) {
        if (value.search)
            this.user_search = value.search;
        if (value.filter)
            this.group = this.parsedirectusFilter(value.filter);
        if (value.sort)
            this.sort = this.parsedirectusSort(value.sort);
        if (value.aggregate)
            this.aggregate = this.parsedirectusAggregate(value.aggregate);
        if (value.groupBy)
            this.aggregate_group = this.parsedirectusAggregateGroup(value.groupBy);
    }

    get query() {
        return this.getQuery();
    }

    set group(value) {
        this._group = value;
        this.render();
    }

    /** @type {import('./app-filter-group').FilterGroup} */
    get group() {
        if (!this._group)
            this._group = {
                type: "_and",
                filters: [],
                groups: []
            }

        return this._group;
    }

    set sort(value) {
        if (!value)
            return this._sort = [];
        this._sort = value;
        this.render();
    }

    get sort() {
        if (!this._sort)
            this._sort = [];
        return this._sort;
    }

    set aggregate(value) {
        if (!value)
            return this._aggregate = [];
        this._aggregate = value;
        this.render();
    }

    get aggregate() {
        if (!this._aggregate)
            this._aggregate = [];
        return this._aggregate;
    }

    set aggregate_group(value) {
        if (!value)
            return this._aggregate_group = [];
        this._aggregate_group = value;
        this.render();
    }

    get aggregate_group() {
        if (!this._aggregate_group)
            this._aggregate_group = [];
        return this._aggregate_group;
    }

    get filters_count() {
        function getGroupCount(group) {
            let sum = 0;
            sum += group.filters?.length || 0;
            if (group.groups?.length)
                for (let sub_group of group.groups)
                    sum += getGroupCount(sub_group);
            return sum;
        }
        let sum = getGroupCount(this.group);
        sum += this.sort?.length || 0;
        return sum;
    }

    get aggregate_count() {
        return (this.aggregate?.length ? this.aggregate.length : 0) + (this.aggregate_group?.length ? this.aggregate_group.length : 0)
    }

    constructor() {
        super();
        this.resetFilters();
    }

    connectedCallback() {
        this.template = () => html`
        <style>
            .app-filters {
                /* border: 1px solid var(--t-color-dark);
                padding: 7px;
                border-radius: 10px 10px;
                transition: all .25s;
                width: 150px; 
                display: block;
                background-color: white; */
            }
            
            .app-filters.expanded {
                /*width: 300px;*/
            }
            .app-filters.expanded input {
                /*width: 300px;*/
                display: block;
            }
            .app-filters-search {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
            .app-filters-search input {
                /*border: none;*/
                /* border-bottom: 1px solid var(--t-color-dark);
                display: none; */
                background-color: white;
                height: 35px;
            }
            .filters-container.sms-settings-filters .app-filters-search input {
                height: 31px !important;
    }
            .app-filters:focus-within {
                /*width: fit-content;*/
               /* width: 300px;*/
            }
            .app-filters:focus-within input {
                /*width: 350px;*/
                display: block;
            }
            .app-filters.show-advanced-filters {
                width: fit-content;
            }

           /* .app-filters-group-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
            
            .app-filters-group-container:hover .app-filters-group-delete{
                display: inline;
            }
            */

            .app-filters-group-prompt:hover {
                font-weight: 600;
                color: var(--t-color-grey);
            }

            .app-filters-group-prompt span {
                font-size: 12px;
                color: var(--t-color-grey);
                margin-left: 3px;
                margin-right: 3px;
            }
            .app-filters-group-prompt {
                color: var(--t-color-light-grey);
                font-size: 12px;
                display: none;
                align-items: center;
                margin-left: 5px;
                cursor: pointer;

            }

            .app-filters-group-label {
                font-size: 12px;
                font-weight: 700;
                color: var(--t-color-primary);
            }
            .app-filters-group-label select {
                color: var(--t-color-primary);
                font-weight: 700;
                border: none;
                background-color: transparent;
            }
            .app-filters-group-content {
                position: relative;
                margin-left: 0px;
                min-height: 25px;
            }
            /*
            .app-filters-group-content::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 10px;
                height: 100%;
                border-left: 1px solid var(--t-color-dark);
                border-top: 1px solid var(--t-color-dark);
                border-bottom: 1px solid var(--t-color-dark);
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            */
            .app-filters-container-label {
                margin-top: 10px;
                font-size: 12px;
                font-weight: 600;
                width: 100%;
                color: var(--t-color-dark)
            }

            .app-filters-group-delete {
                display: none;
                flex-direction: row;
                align-items: center;
            }

            .app-filters-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 0px 0px 0px;
                /* height: 39px; */
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                padding-right: 5px;
                border: 1px solid #dfdfdf;
                width: 100%;
                background: #Eee;
                border-radius: 3px;
                justify-content: space-between;
                gap: 1%;
            }

            .app-filters-item select {
                border: none;
                font-size: 14px;
                color: var(--t-color-dark);
            }
            .app-filters-item .dropdown {
                /*width:50%;*/
            }


        </style>
     <div tabindex="0" class="app-filters ${this.expanded ? 'expanded' : ''}">
        <div class="app-filters-search input-group" tabindex="1">
            <span class="material-symbols-outlined input-group-text"
                style='
                        font-size: 14px;
                        cursor: pointer;
                        margin-right: 0px;
                        /* line-height: 1.5; */
                        color: var(--t-color-primary);
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                '>search</span>
            <input class="app-filters-search-input form-control" .value=${this.user_search} @input=${e => this.handleSearchChange(e, e.target.value)} type="text">

            ${this.show_filters ? html`
            <div class="input-group-text" style="position: relative;">
                <span @click=${(e) => this.handleShowFilters(e)}
                    class="material-symbols-outlined"
                    style='
                        font-size: 14px;
                        cursor: pointer;
                        line-height: 1.5;
                        margin: 0 0px 0 0px;
                        color: var(--t-color-primary);
                        font-variation-settings: "FILL" 1, "wght" 700;
                    '>filter_list</span>
                ${this.filters_count ? html`
                <span style="position: absolute; bottom: 0px; right: 0px; font-size: 8px;" class="badge rounded-pill bg-danger">
                    ${this.filters_count}
                </span>
                ` : ''}
            </div>
            ` : ''}

            ${this.enable_aggregate ? html`
            <div class="input-group-text" style="position: relative; height:35px;">
                <span @click=${(e) => this.handleShowAggregate(e)}
                    class="material-symbols-outlined"
                    style='
                        font-size:13px;
                        cursor: pointer;
                        margin-right: 0px;
                        line-height: 1.5;
                        color: var(--t-color-primary);
                        font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                    '>monitoring</span>
                ${this.aggregate_count ? html`
                <span style="position: absolute; bottom: 0px; right: 0px; font-size: 8px;" class="badge rounded-pill bg-danger">
                    ${this.aggregate_count}
                </span>
                ` : ''}
            </div>
            ` : ''}
        </div>
    </div>
`;


        this.style.display = this.style.display || "block";
        this.render();
    }

    async init() {
        this.addEventListener("item-change", e => this.handleFilterChange())
        this.addEventListener("set-focus", e => {
            let search_element = this.querySelector('.app-filters-search');
            search_element.focus({ focusVisible: true });
        })
        if (this.config.collection.auto_configure)
            await this.autoConfigure();
        this.render();
    }

    render() {
        if (!this.template)
            return;
        if (!this.config)
            return;

        render(this.template(), this);
    }

    resetFilters() {
        this.user_search = '';
        this._aggregate = [];
        this._aggregate_group = [];
        this._group = {
            type: "_and",
            filters: [],
            groups: []
        }
        this._sort = [];
    }

    /**
     * Convert internal filter_state to a directus query
     * @param {FilterState} filter_state - Optional. If not passed in, will use internal filter state
     */
    getQuery(filter_state) {
        if (!filter_state)
            filter_state = this.filter_state;

        return AppFilterUtil.getQuery(this.config.collection, filter_state);

    }

    async handleShowFilters(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let modal = new AppFilterAdvancedModal();
        modal.disable_sort = this.disable_sort;
        modal.config = this.config;
        modal.group = this.group;
        modal.sort = this.sort;

        await modal.showModal();

        let { group, sort } = await modal.onDidDismiss();

        if (group)
            this.group = group;
        if (sort)
            this.sort = sort;

        this.handleFilterChange();
    }

    async handleShowAggregate(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        let modal = new AppFilterAggregateModal();
        modal.config = this.config;
        modal.aggregate = this.aggregate;
        modal.aggregate_group = this.aggregate_group;
        await modal.showModal();
        let { aggregate, aggregate_group } = await modal.onDidDismiss();
        if (aggregate)
            this.aggregate = aggregate;
        if (aggregate_group)
            this.aggregate_group = aggregate_group;
        this.handleFilterChange();
    }

    handleSearchChange(e, value) {
        e.stopImmediatePropagation();
        this.user_search = value;

        //throttle search
        if (this._search_timeout)
            clearTimeout(this._search_timeout)
        this._search_timeout = setTimeout(() => this.handleFilterChange(), 400);
    }

    handleFilterChange() {
        this.dispatchEvent(
            new CustomEvent('filter_change', {
                composed: true,
                bubbles: true,
                detail: this.filter_state
            })
        );
    }

    parsedirectusFilter(query) {
        /** @type {import('./app-filter-group').FilterGroup} */
        let group = {};
        let items;
        let filters = [];
        let groups = [];
        if (query._and) {
            group.type = "_and";
            items = query._and;
        }
        else if (query._or) {
            group.type = "_or";
            items = query._or;
        }
        else
            throw new Error("Root of query must be a group");

        for (let item of items) {
            if (item._and) {
                groups.push(this.parsedirectusFilter(item._and));
                continue;
            }
            if (item._or) {
                groups.push(this.parsedirectusFilter(item._or));
                continue;
            }

            let field = Object.keys(item)[0];
            let body = item[field];
            let op = Object.keys(body)[0];
            //flatten keys
            while (!op.startsWith('_')) {
                field = `${field}.${op}`;
                body = body[op];
                op = Object.keys(body)[0];
            }
            let value = body[op];
            /** @type {import('./app-filter-item').FilterItem} */
            let filter = { field, op, value };
            filters.push(filter);
        }
        group.filters = filters;
        group.groups = groups;
        return group;
    }

    //TODO: extract all of these functions and parseSortQuery etc.. into app-filter-util library, detach their functionality from the DOM
    parsedirectusSort(query) {
        let filter_sort = new AppFilterSort();
        filter_sort.parseSortQuery(query);
        this.sort = filter_sort.sort;
    }

    parsedirectusAggregate(query) {
        let filter_aggregate = new AppFilterAggregate();
        filter_aggregate.parseAggregateQuery(query);
        this.aggregate = filter_aggregate.aggregate;
    }

    parsedirectusAggregateGroup(query) {
        let aggregate_group = new AppFilterAggregateGroup();
        aggregate_group.parseAggregateGroupQuery(query);
        this.aggregate_group = aggregate_group.aggregate_group;

    }

    autoConfigure() {
        this.config.collection.configured = this.configureCollection(this.config.collection);
    }


    /**
     * Configure collection fields including handling of alias fields and relations
     * @param {CollectionConfig} collection - The collection to configure
     * @returns {Promise<boolean>} True if configuration was successful
     */
    async configureCollection(collection) {
        if (!collection.depth)
            return;

        let definition = await DataDefinition.getDefinition(collection.name);
        const rules = this.config.field_rules && this.config.field_rules[collection.name] || null;
        const fields = definition.getFieldDefinition();
        const configured_fields = [];

        // First pass: Add all fields that pass the rules
        for (let field of fields) {
            if (rules && rules.mode) {
                const fieldInRules = rules.fields.indexOf(field.field) !== -1;
                if ((rules.mode === "whitelist" && !fieldInRules) ||
                    (rules.mode === "blacklist" && fieldInRules)) {
                    continue;
                }
            }

            // Handle alias fields by getting their target type
            if (field.type === "alias") {
                field.type = await DataDefinition.getFieldType(collection.name, field.field);
            }

            configured_fields.push({
                ...field,
                collection: collection.name
            });
        }

        // Handle foreign key relationships
        for (let field of configured_fields) {
            if (field.schema?.foreign_key_column) {
                field.related_collection = {
                    name: field.schema.foreign_key_table,
                    auto_configure: true,
                    depth: collection.depth - 1
                };
                await this.configureCollection(field.related_collection);
            }
        }

        collection.fields = configured_fields;
        return true;
    }
}

customElements.define('app-filter', AppFilter);
export default AppFilter;