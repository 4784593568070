/**
 * Utility functions for working with Google Places
 */
class GooglePlaces {
    static async loadGooglePlacesScript() {
        if (window.google?.maps?.places) {
            console.log('Google Places already loaded');
            return true;
        }

        return new Promise((resolve, reject) => {
            try {
                window.initGooglePlaces = () => {
                    console.log('Google Places initialization callback triggered');
                    if (window.google?.maps?.places) {
                        console.log('Google Places successfully loaded');
                        resolve(true);
                    } else {
                        console.error('Google object not properly initialized');
                        reject(new Error('Google Places initialization failed'));
                    }
                };

                const params = new URLSearchParams({
                    key: 'AIzaSyDFrXnWnYIZXnLDbNKpq6kFOKq1Gxw41yA',
                    libraries: 'places',
                    callback: 'initGooglePlaces'
                });

                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
                script.async = true;

                script.addEventListener('load', () => {
                    console.log('Script load event fired');
                });

                script.addEventListener('error', (e) => {
                    console.error('Script load error:', e);
                    reject(new Error('Failed to load Google Maps script'));
                });

                document.head.appendChild(script);

            } catch (error) {
                console.error('Script creation error:', error);
                reject(error);
            }
        });
    }

    static initAutocomplete(inputElement, callback) {
        try {
            const autocomplete = new google.maps.places.Autocomplete(inputElement, {
                types: ['establishment'],
                fields: ['place_id', 'name', 'formatted_address']
            });

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place) {
                    callback(place);
                }
            });

            return autocomplete;
        } catch (error) {
            console.error('Error initializing autocomplete:', error);
            return null;
        }
    }

    /**
     * Generate a direct link to view the business's Google reviews
     * @param {string} placeId - The Google Place ID
     * @returns {string} URL to the reviews page
     */
    static getReviewsUrl(placeId) {
        return `https://search.google.com/local/reviews?placeid=${placeId}`;
    }

    /**
     * Generate a direct link for customers to write a review
     * @param {string} placeId - The Google Place ID
     * @returns {string} URL to write a review
     */
    static getWriteReviewUrl(placeId) {
        return `https://search.google.com/local/writereview?placeid=${placeId}`;
    }

    /**
     * Get the Google Maps URL for the business
     * @param {string} placeId - The Google Place ID
     * @returns {string} Google Maps URL
     */
    static getMapsUrl(placeId) {
        return `https://www.google.com/maps/place/?q=place_id:${placeId}`;
    }

    /**
     * Generate a shareable review link for SMS templates
     * @param {string} placeId - The Google Place ID
     * @returns {string} A formatted message with the review link
     */
    static getReviewMessageTemplate(placeId) {
        const reviewUrl = this.getWriteReviewUrl(placeId);
        return `Please take a moment to share your experience: ${reviewUrl}`;
    }

    /**
     * Copy review link to clipboard
     * @param {string} placeId - The Google Place ID
     */
    static async copyReviewLinkToClipboard(placeId) {
        const url = this.getWriteReviewUrl(placeId);
        await navigator.clipboard.writeText(url);
    }
}

export default GooglePlaces; 