import { html, render } from "lit";

import SceneSettingsCareTeam from "./scene-settings-careteam";
//import SceneSettingsLanding from "shared/scenes/scene-settings-landing";
import SceneSettingsNotifications from "./scene-settings-notifications";
//import SceneSettingsImpact from "./scene-settings-impact";
//import SceneSettingsKPIs from "./scene-settings-kpis";
import SceneSettingsSMS from "./scene-settings-sms";
import SceneSettingsSchedule from "./scene-settings-schedule";

import "shared/components/app-page-header";
import SceneSettingsCompany from "./scene-settings-company";

export default class SceneSettings extends HTMLElement {
    get links() {
        return [
            //{ title: "Settings", page: "landing", url: "settings" },
            { title: "Company", page: "company", url: "settings" },
            { title: "Care Team", page: "careteam", url: "settings/careteam" },
            { title: "Notifications", page: "notifications", url: "settings/notifications" },
            //{ title: "Financial Impact", page: "impact", url: "settings/financial-impact" },
            //{ title: "Agency KPIs", page: "kpis", url: "settings/client-kpis" },
            { title: "SMS Templates", page: "sms", url: "settings/sms-template" },
            { title: "Schedule", page: "schedule", url: "settings/schedule" },
        ];
    }

    get page() {
        return this._page;
    }

    set page(value) {
        this._page = value;
        this.render();
    }

    get error_message() {
        return this._error_message;
    }

    set error_message(value) {
        this._error_message = value;
    }

    get location() {
        return this._location;
    }

    set location(value) {
        // check if the path is the same, don't re-render if so.
        if (this._location && this._location.path === value.path) return;
        this._location = value;
        switch (value.route.path) {
            case "/settings":
                this.component = new SceneSettingsCompany();
                this.page = "company";
                break;
            case "/settings/careteam":
                this.component = new SceneSettingsCareTeam();
                this.page = "careteam";
                break;
            case "/settings/notifications":
                this.component = new SceneSettingsNotifications();
                this.page = "notifications";
                break;
            case "/settings/financial-impact":
                this.component = new SceneSettingsImpact();
                this.page = "impact";
                break;
            case "/settings/client-kpis":
                this.component = new SceneSettingsKPIs();
                this.page = "kpis";
                break;
            case "/settings/sms-template":
                this.component = new SceneSettingsSMS();
                this.page = "sms";
                break;
            case "/settings/schedule":
                this.component = new SceneSettingsSchedule();
                this.page = "schedule";
                break;
        }
        this.render();
    }

    constructor() {
        super();
        this._page = "company";
        this.component = new SceneSettingsCompany();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <style>
                    scene-settings .scroll-container {
                        /* overflow-y: scroll !important; */
                        height: calc(100% - 171px);
                    }
                </style>
                <app-page-header
                    style="margin: 0 32px 20px 32px;"
                    .icon=${"settings"}
                    .title=${this.page ? `Settings - ${this.page}` : 'Settings'}
                    .links=${this.links}
                    .page=${this.page}
                ></app-page-header>
                <div class="scroll-container mod-base careteam-wrapper">
                    ${this.component}
                </div>
            `
        };

        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("scene-settings", SceneSettings);