import { format } from "date-fns";
import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import directus from "../../lib/lib-directus";
import AppCalendarViewModal from "../availability/app-calendar-view-modal";
import AppTaskSelfCheckModal from "../app-task-self-check-modal";
import { getCurrentClientId } from "../../lib/lib-user";

export default class AppSMSListModal extends ModalBase {
    constructor(SMSes, sms, showButton = false, patient = null) {
        super();
        this.SMSes = SMSes;
        this.sms = sms;
        this.showButton = showButton;
        this.patient = patient;
        this.patient_name = patient?.first_name ? `${patient.first_name} ${patient.last_name}` : 'Patient';
    }

    connectedCallback() {
        this.template = () => html`
            <style>
                .chat {
                    width: 490px;
                }
                .chat .chat-history {
                    padding: 30px 30px 20px;
                    border-bottom: 2px solid white;
                    overflow-y: auto;
                    height: 575px;
                }
                .chat .chat-history .message-data {
                    margin-bottom: 15px;
                }
                .chat .chat-history .message-data-time {
                    color: #a8aab1;
                    padding-left: 6px;
                }
                .chat .chat-history .message {
                    color: white;
                    padding: 10px 20px;
                    line-height: 26px;
                    font-size: 16px;
                    border-radius: 7px;
                    width: 90%;
                    position: relative;
                    margin-bottom: 5px;
                }
                .chat .chat-history .message:after {
                    bottom: 100%;
                    left: 7%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-bottom-color: #86BB71;
                    border-width: 10px;
                    margin-left: -10px;
                }
                .chat .chat-history .my-message {
                    background: #86BB71;
                }
                .chat .chat-history .other-message {
                    background: #94C2ED;
                }
                .chat .chat-history .other-message:after {
                    border-bottom-color: #94C2ED;
                    left: 93%;
                }
                .align-right {
                    text-align: right;
                }
                .float-right {
                    float: right;
                }
                .clearfix:after {
                    visibility: hidden;
                    display: block;
                    font-size: 0;
                    content: " ";
                    clear: both;
                    height: 0;
                }
                .selected-sms {
                    /*box-shadow: 6px 7px 0px #666;*/
                    /* outline: #ee6e73 solid 5px; */
                    /*filter: drop-shadow(6px 7px 0px #666);*/
                }
            </style>
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down" id="sms-list-modal">
                <div class="modal-content ${this.type}">
                    <div class="modal-header">
                        <h5 class="modal-title">SMS Conversation</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="chat">
                        <div class="chat-history">
                            ${this.SMSes.map((sms, idx) => html`
                            <div class="${sms.caller_type == "patient" ? "in-message clearfix" : ""}" attr-id="${idx}">
                                <div class="message-data ${sms.caller_type == "patient" ? "align-right" : ""}">
                                    ${sms.caller_type != "patient" ? html`<span class="message-data-name">You</span>` : ""} 
                                    <span class="message-data-time">${this.formatTime(sms.scheduled_date)}</span>
                                    ${sms.caller_type == "patient" ? html`<span class="message-data-name">${this.patient_name}</span>` : ""}
                                </div>
                                <div class="message ${sms.caller_type == "patient" ? "other-message float-right" : "my-message"} ${sms.id == this.sms.id ? "selected-sms" : ""}">
                                    ${sms.sms_message}
                                </div>
                            </div>
                            `)}
                        </div>

                    </div>
                    ${this.showButton ? html`
                        <div class="sms-modal-actions">
                            <button
                                type="button"
                                class="btn light-purple-btn view-calendar-button"
                                @click=${() => this.handleViewCalendar()}>
                                View Calendar
                            </button>
                            <button
                                type="button"
                                class="btn light-purple-btn view-sms-button"
                                @click=${() => this.handleScheduleMessage()}>
                                Schedule Message
                            </button>
                        </div>` : ""
            }
                    
                </div>
            </div>
        `;

        this.render();

    }

    formatTime(time) {
        try {
            const localDate = new Date(time + 'Z'); // Ensure UTC format
            return format(localDate, "p, PP");
        } catch (error) {
            return time
        }
    }

    scrollTo() {
        const element = this.querySelector("#sms-end");
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        if (!this.template) return;

        render(this.template(), this);
        requestAnimationFrame(() => {
            this.scrollTo();
        });
    }

    hideSMSList() {
        this.querySelector("#sms-list-modal").hidden = true;
    }

    showSMSList() {
        this.querySelector("#sms-list-modal").hidden = false;
    }

    handleViewCalendar() {
        const modal = new AppCalendarViewModal(this.patient);
        modal.showModal();
        this.dismiss();
    }

    async handleScheduleMessage() {
        // Get patient data if needed
        if (!this.patient && this.sms?.patient_id) {
            const patientId = this.sms.patient_id.id || this.sms.patient_id;
            this.patient = await directus.items('patient').readOne(patientId);
        }

        if (!this.patient) {
            console.error('Cannot schedule message: Missing patient data');
            return;
        }

        // Create the modal
        const modal = new AppTaskSelfCheckModal();

        // Create a patient clone
        const patientClone = { ...this.patient };
        // Just use the patient's client_id directly - it's already a string
        modal.patient = patientClone;
        modal.type = 'general';

        // Hide this modal
        const currentModal = this.querySelector('#sms-list-modal');
        if (currentModal) currentModal.style.display = 'none';

        try {
            await modal.showModal();
            const result = await modal.onDidDismiss();
            if (result?.confirmed) await this.fetchSMS();
        } finally {
            if (currentModal) currentModal.style.display = '';
        }
    }

    async fetchSMS() {
        if (!this.patient || !this.patient.id) {
            console.error('Cannot fetch SMS: Missing patient data');
            return;
        }

        const sms_result = await directus.items("patient_communication").readByQuery({
            filter: {
                communication_type: "sms",
                patient_id: this.patient.id
            },
            sort: ['scheduled_date']
        });

        this.SMSes = sms_result.data;
        this.render();
    }
}

customElements.define("app-sms-list-modal", AppSMSListModal);
