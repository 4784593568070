import { Router } from "@vaadin/router";

let router = null;

/** @todo: ditch the nested routes and use component composition */

/**
 * @param {Element} element an html element, usually the app itself
 * @returns {Router} a vaadin router https://github.com/vaadin/router
 */
function init(element, routes) {
    if (!router) {
        router = new Router(element, { enableNativeViewTransitions: true });
        router.setRoutes(routes);
    }
    return router;
}

/**
 * @returns {Router} a vaadin router https://github.com/vaadin/router
 */
function getRouter() {
    if (!router) throw Error("Router was not initialized");
    return router;
}

function buildURL(path) {
    let base = document.querySelector("base");
    let base_url = new URL(base.href).pathname;
    return `${base_url}${path}`;
}

function navigate(path) {
    return new Promise((resolve) => {
        Router.go(buildURL(path));
        // Wait for the next tick to ensure navigation has started
        setTimeout(resolve, 0);
    });
}

export { router, buildURL, navigate };

export default {
    init,
    getRouter,
};
